import { Dropdown, Flex, Popconfirm, Table } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import toast from 'react-hot-toast';
import { Member, StagesDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import Icon from '../../ui/Icon';
import ProgressBar from '../../ui/ProgressBar';
import IconButton from '../../ui/IconButton';

type WorkspaceIdParams = {
  id?: string;
};

const Members: React.FC = () => {
  const { id } = useParams<WorkspaceIdParams>();
  const [loading, setLoading] = useState(false);
  const [stages, setStages] = useState<StagesDataType[]>([]);

  useEffect(() => {
    if (!id) return;
    (async () => {
      setLoading(true);
      try {
        if (!Object.keys(StagesStore.dataStatus)?.length) {
          await StagesStore.getUserStatus(id);
        }
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
        console.log(res);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      try {
        const res = await WorkspacesStore.getJobsInWork.call(id);
        WorkspacesStore.setInWorkJobs(res.data);
        console.log(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      await StagesStore.getList(id);
      setStages(StagesStore.dataList);
      setLoading(false);
    })();
  }, [id]);

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text: any, record: Member) => (
        <div className={styles.nameBlock}>
          <div className={styles.photo}>{text[0]}</div>
          <div className={styles.infoBlock}>
            <p className={styles.name}>{text}</p>
            <div className={styles.status}>
              <div />
              {StagesStore.dataStatus[record._id.toString()]}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Активные заказы',
      key: '1',
      render: (_: any, record: Member) => {
        const job = WorkspacesStore.inWorkJobs[record._id]?.[0];

        if (!job) return '-';

        return (
          <div className={styles.activeJobBlock}>
            <Flex align="center" justify="space-between">
              <div className={styles.copyBlock}>
                #{job.jobId}
                <div>
                  <Icon slug="copy" />
                </div>
              </div>
              <div className={styles.status}>
                <div></div>
                {StagesStore.dataList.find((item) => item._id === job.stageId)?.name}
              </div>
            </Flex>
            <ProgressBar status="in_progress" />
          </div>
        );
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <div className={styles.statusBlock}>
          <div></div>
          {text}
        </div>
      ),
    },
    {
      key: '4',
      render: (_: string, record: Member) => (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <Popconfirm
                    title="Bы действительно хотите удалить пользователя?"
                    okText="Да"
                    cancelText="Нет"
                  >
                    <div>Удалить</div>
                  </Popconfirm>
                ),
              },
            ],
          }}
          trigger={['click']}
        >
          <IconButton icon="three_dots" variant="secondary" />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.membersPage}>
      <div className={styles.membersPageTop}>
        <p className={styles.title}>Команда</p>
        <IconButton icon="plus" size="lg" />
      </div>
      <Table
        columns={columns}
        dataSource={Object.values(WorkspacesStore.members)}
        loading={loading}
        rowKey="_id"
      />
    </div>
  );
};

export default observer(Members);
