import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { IWorkspace } from '../../../DataSource/Stores/Cabinet/WorkspacesStoreActionsClass';
import LayoutStore from '../../../DataSource/Stores/LayoutStore';
import { pluralize } from '../../../utils';
import Clickable from '../../ui/Clickable';
import styles from './Item.module.scss';

const Item = ({ workspace }: { workspace: IWorkspace }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.workspace_card}>
      {/*<IconButton*/}
      {/*  className={styles.workspace_card_settings}*/}
      {/*  variant="clear"*/}
      {/*  icon="fi-rr-menu-dots"*/}
      {/*  onClick={() => LayoutStore.openModal('workspaceSettings', { workspace })}*/}
      {/*/>*/}
      <Button
        size={'small'}
        className={styles.workspace_card_settings}
        onClick={() => LayoutStore.openModal('workspaceSettings', { workspace })}
      >
        <MenuUnfoldOutlined />
      </Button>
      <Clickable
        className={styles.workspace_card_body}
        onClick={() => {
          if (workspace.category === 'order_management') navigate(`/workspaces/${workspace._id}`);
          if (workspace.category === 'aiAssistant')
            window.location.href = `/aida/${workspace._id}/schedule`;
        }}
      >
        <div className={styles.workspace_card_name}>{workspace.name}</div>
        <div className={styles.workspace_card_members}>
          {pluralize(workspace.members.length || 0, ['участник', 'участника', 'участников'])}
        </div>
      </Clickable>
    </div>
  );
};

export default observer(Item);
