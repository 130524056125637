import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { SwipeableProps, useSwipeable } from 'react-swipeable';
import styles from './index.module.scss';

interface ButtonProps {
  name: string;
  onClick: () => void;
}

const SliderButton: FC<ButtonProps> = ({ name, onClick }) => {
  const [slideWidth, setSlideWidth] = useState(0);
  const [isFullySlid, setIsFullySlid] = useState(false);
  const [isGrenClass, setIsGrenClass] = useState(false);

  const handleSwipeRight = () => {
    setIsGrenClass(true);
    onClick();
  };
  const swipeHandlers = useSwipeable({
    onSwiping: (event) => {
      const maxSlideWidth = 100; // 100% ширины кнопки
      const currentSlideWidth = Math.min(maxSlideWidth, event.deltaX);
      setSlideWidth(currentSlideWidth);

      if (currentSlideWidth >= maxSlideWidth) {
        setIsFullySlid(true);
      } else {
        setIsFullySlid(false);
      }
    },
    onSwiped: (event) => {
      if (isFullySlid) {
        handleSwipeRight();
      } else {
        setSlideWidth(0);
      }
    },
    preventDefaultTouchEvent: true,
    trackMouse: true,
  } as SwipeableProps);

  return (
    <div
      className={`${styles.call_button} ${isGrenClass ? styles.fully_slid : ''}`}
      {...swipeHandlers}
    >
      <div
        className={`${styles.slider}`}
        style={{
          left: `${Math.min(100, slideWidth)}%`,
        }}
      >
        <div className={styles.slider_inner}>
          <svg
            width="30"
            height="22"
            viewBox="0 0 30 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6711 22V16.2864H0.764648V5.76212H15.648V0L29.2352 11.0085L15.6711 22Z"
              fill="#E1A719"
            />
          </svg>
        </div>
      </div>
      <span className={styles.button_text}>{name}</span>
    </div>
  );
};

export default observer(SliderButton);
