import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import logo from '../../assets/Logo-new.svg';
import Lang from '../Lang/index';
import styles from './Header.module.scss';
import Clickable from '../ui/Clickable';

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const logOut = () => {
    console.log('User logged out');
    AuthStore.logout();
    navigate('/');
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="/workspaces/66e170b52fc0a03d58789c24/profile">
          {Lang({ str: 'profile', section: 'default' })}
        </a>
      ),
    },
    {
      key: '2',
      label: <a onClick={() => setOpen(true)}>{Lang({ str: 'exit', section: 'default' })}</a>,
    },
  ];

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="" />
        <div className={styles.flex_bl}>
          <Dropdown trigger={['click']} menu={{ items }}>
            <Clickable className={styles.dropdownBtn}>
              {isMobile ? (
                <UserOutlined />
              ) : (
                AuthStore.currentUserData.user.firstname +
                ' ' +
                AuthStore.currentUserData.user.secondname
              )}
              <DownOutlined />
            </Clickable>
          </Dropdown>
        </div>
      </div>
      <Modal
        title=""
        open={open}
        closable={false}
        onOk={logOut}
        onCancel={() => setOpen(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to leave the site?</p>
      </Modal>
    </>
  );
};
export default observer(Header);
