import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';
import { config } from './config';
import { useEffect, useState } from 'react';

const $class = (...classes: any[]) => {
  return classes
    .map((x) => (Array.isArray(x) ? (x[1] ? x[0] : null) : x))
    .filter((x) => x)
    .join(' ');
};

/**
 *
 * @param {number} amount
 * @param {string[]} cases For slavic languages: ['яблоко', 'яблока', 'яблок']
 * @returns {string}
 */
function pluralize(amount: number, cases: string[], show_amount: boolean = true) {
  if (amount === undefined) return '';
  if (cases.length === 1) return `${amount} ${cases[0]}`;
  const indexes = [2, 0, 1, 1, 1, 2];
  const mod100 = amount % 100;
  const mod10 = amount % 10;
  const index = mod100 > 4 && mod100 < 20 ? 2 : indexes[mod10 < 5 ? mod10 : 5];
  return `${show_amount ? amount + ' ' : ''}${cases[index]}`;
}

const $shimmer = (loading = true, type = 'block') => {
  return $class('shimmer', ['shimmer--active', loading], `shimmer--${type}`);
};

const formatTime = (time: number) => {
  if (time < 60) return time.toString();

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

const formatPhone = (phone: string) => {
  // Удаляем все символы, кроме цифр
  const cleaned = phone.replace(/\D/g, '');

  // Разделяем части номера
  const country = cleaned.slice(0, 1);
  const operator = cleaned.slice(1, 4);
  const part1 = cleaned.slice(4, 7);
  const part2 = cleaned.slice(7, 9);
  const part3 = cleaned.slice(9, 11);

  // Форматируем номер телефона
  const formatted = `+${country} (${operator}) ${part1} ${part2}-${part3}`;

  return formatted;
};

const api = axios.create({ baseURL: `${config.EXTERNAL_ADDRESS}/` });
// const api = axios.create({ baseURL: 'http://127.0.0.1:3000/' });

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class RequestData<T extends any[], D = null> {
  data: D;
  loading = false;
  error: Partial<AxiosError> | null = null;
  _callback: any;

  constructor(callback: (...args: T) => any, initData: D | null = null, initLoading = false) {
    this._callback = callback;
    this.data = initData as D;
    this.loading = initLoading;

    makeAutoObservable(this);
  }

  async call(...args: T) {
    try {
      this.error = null;
      this.loading = true;

      const res: AxiosResponse<D, any> = await this._callback(...args);
      this.data = res.data;

      this.loading = false;
      return res;
    } catch (e: any) {
      if (isAxiosError(e)) {
        this.error = e.response?.data;
      } else {
        this.error = { message: e.message };
      }

      this.loading = false;
      throw this.error;
    }
  }

  mutate(data: MutateFunc<D> | D) {
    if (typeof data === 'function') {
      this.data = (data as MutateFunc<D>)(this.data);
      return;
    }

    this.data = data;
  }
}

type MutateFunc<T> = (oldData: T) => T;

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export { $class, $shimmer, api, formatPhone, formatTime, pluralize, RequestData, useDebounce };
