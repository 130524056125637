import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import './scss/index.scss';
import { config } from './config';
import { AuthStore } from './DataSource/Stores/AuthStore';
import { HeaderStore } from './DataSource/Stores/HeaderStore';
import { Provider } from 'mobx-react';
import { configure as configureMobx } from 'mobx';
import { Modals } from './layout/index';
import { Toaster } from 'react-hot-toast';
import { ConfigProvider } from 'antd';

configureMobx({
  // enforceActions: 'observed',
  enforceActions: 'never',
});

const mobxStores = {
  AuthStore,
  HeaderStore,
};

// загрузка информации о версии приложения после конфига
config.load().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as Element);
  root.render(
    <Provider {...mobxStores}>
      <React.StrictMode>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Inter',
            },
          }}
        >
          <Modals />
          <App />
          <Toaster />
        </ConfigProvider>
      </React.StrictMode>
    </Provider>
  );
});
