// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vY3qcB10bhqXRhvMhJKd{margin-bottom:24px;font-size:24px;color:#2a3038;font-family:"Inter",sans-serif;font-weight:400;display:flex;align-items:center;justify-content:space-between}.vY3qcB10bhqXRhvMhJKd span{display:block}.Q88lFVXsVFSR4M_fV24g{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.module.scss"],"names":[],"mappings":"AAIA,sBACE,kBAAA,CACA,cAAA,CACA,aAAA,CACA,8BAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,2BACE,aAAA,CAGJ,sBACE,UAAA","sourcesContent":["@import '../../scss/variables';\n\n@import '../../scss/mixins';\n\n.title {\n  margin-bottom: 24px;\n  font-size: 24px;\n  color: #2a3038;\n  font-family: 'Inter', sans-serif;\n  font-weight: 400;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  span {\n    display: block;\n  }\n}\n.button {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `vY3qcB10bhqXRhvMhJKd`,
	"button": `Q88lFVXsVFSR4M_fV24g`
};
export default ___CSS_LOADER_EXPORT___;
