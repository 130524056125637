import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import uWS from '../../../uWS';
import NewJobsBlock from '../NewJobsBlock';
import styles from './index.module.scss';
import { WorkspacesStore } from '../../../DataSource/Stores/Cabinet/WorkspacesStore';
import toast from 'react-hot-toast';

type WorkspaceIdParams = {
  id?: string;
};

const Workspaces = () => {
  const { id } = useParams<WorkspaceIdParams>();
  const [view, setView] = useState<'list' | 'columns'>('columns');
  const navigate = useNavigate();
  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }
    })();
  }, [id]);
  // вот это как пример подписки на смену статуса операторов в ра��очем пространстве
  useEffect(() => {
    if (!id) return;
    const eventStatus = uWS.subscribe(`workspaces/${id}/userOnline`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace user online:', data.error);
        return;
      }

      console.log('Online user:', data);
      // OperatorsStore.setOnlineUser(data);
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${id}/userOnline`);
    };
  }, [id]);

  return (
    <div className={styles.distributionCenterPage}>
      <div className={styles.distributionCenterPageTop}>
        <p className={styles.title}>Центр распределения</p>
      </div>
      {id && <NewJobsBlock workid={id} view={view} />}
    </div>
  );
};

export default Workspaces;
