import { ModalProps } from '../../layout/Modals';
import styles from './index.module.scss';
import { useState } from 'react';
import { WorkspacesStore } from '../../DataSource/Stores/Cabinet/WorkspacesStore';
import { observer } from 'mobx-react-lite';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import { IWorkspace } from '../../DataSource/Stores/Cabinet/WorkspacesStoreActionsClass';
import toast from 'react-hot-toast';

interface IProps {
  workspace: IWorkspace;
}

const WorkspaceAddMember = ({ data, closeModal }: ModalProps<IProps>) => {
  const { workspace } = data;
  const [email, setEmail] = useState('');

  const add = async () => {
    if (email === '') {
      return;
    }

    try {
      await WorkspacesStore.inviteAdd.call(workspace._id, email);
      await WorkspacesStore.getList();
      closeModal();
      toast.success('Invite добавлен');
    } catch (e: any) {
      console.error(e);
      toast.error(e.message.message);
    }
  };

  return (
    <div className={styles.wrap}>
      <h4>Add Member of {workspace.name}</h4>
      <Input value={email} name={'email'} onChange={(e) => setEmail(e.target.value)} />
      <Button onClick={add} loading={WorkspacesStore.inviteAdd.loading}>
        Invite
      </Button>
    </div>
  );
};

export default observer(WorkspaceAddMember);
