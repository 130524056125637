import { ConfigProvider, Layout } from 'antd';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import { HeaderStore } from '../DataSource/Stores/HeaderStore';
import Nav from '../components/Cabinet/Nav';
import Header from '../components/Header/Header';
import styles from './index.module.scss';
import Sider from 'antd/es/layout/Sider';
import { CSSProperties } from 'react';

function CabinetLayout() {
  const onClose = () => {
    HeaderStore.setOpenRight(false);
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' });

  const siderStyle: CSSProperties = {
    backgroundColor: '#F3F5F6',
    padding: '180px 48px',
  };

  return (
    <ConfigProvider>
      <Layout>
        <Sider width="auto" style={siderStyle}>
          <Nav />
        </Sider>
        <div className={styles.cabinetLayout}>
          <Header />
          <Outlet />
        </div>
      </Layout>
    </ConfigProvider>
  );
}

export default observer(CabinetLayout);
