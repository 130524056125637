import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { JobsDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import uWS from '../../../../uWS';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import ProgressBar from '../../../ui/ProgressBar';
import JobDrawer from './JobDrawer';
import styles from './index.module.scss';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { useDraggable } from '@dnd-kit/core';

const JobColumnItem: FC<JobsDataType> = ({
  _id,
  name,
  stageId,
  jobId,
  createdAt,
  jobData,
  workspaceId,
  externalId,
  prodImage,
  targetDate,
  assignedTo,
  comments,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
  //   id: _id,
  //   data: { stageId },
  // });
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: _id,
    data: { stageId },
  });

  const style = {
    // transition,
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? '1' : '0',
    boxShadow: isDragging ? '0 4px 12px 0 rgba(0, 0, 0, 0.2)' : 'none',
  };

  const showModal = () => {
    setIsModalOpen(true);
    StagesStore.getJobsId(workspaceId, _id).catch((e) => {
      console.error(e);
    });
  };

  const showModalForce = (forceId: string) => {
    setIsModalOpen(true);
    StagesStore.getJobsId(workspaceId, forceId).catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    if (!StagesStore?.dataJobs?.job?._id) return;
    const eventStatus = uWS.subscribe(
      `jobs/${StagesStore.dataJobs.job._id}/changeTaskStatus`,
      (data: any) => {
        if (data.error) {
          console.error('Error subscribing to jobs changeTaskStatus:', data.error);
          return;
        }

        console.log('changeTaskStatus:', data);
        if (!StagesStore?.dataJobs?.job?._id) return;

        if (StagesStore?.dataJobs?.job?._id !== data.jobId) {
          return;
        }

        console.log('Ljitk');
        // StagesStore.getJobsId(StagesStore.dataJobs.job._id as string).catch((e) => {
        //   console.error(e);
        // });
        showModalForce(StagesStore.dataJobs.job._id);
      }
    );

    return () => {
      if (eventStatus && StagesStore?.dataJobs?.job?._id)
        uWS.unSubscribe(`jobs/${StagesStore.dataJobs.job._id}/changeTaskStatus`);
    };
  }, [StagesStore?.dataJobs?.job?._id]);

  const member = assignedTo ? WorkspacesStore.members[assignedTo] : null;

  return (
    <>
      <div ref={setNodeRef} className={styles.jobColumnItem} style={style}>
        <div className={styles.dragHandle} {...attributes} {...listeners}>
          <Icon slug="drag_handle" />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>{name}</p>

          <div className={styles.infoBlock}>
            <Button className={styles.id} autoWidth onClick={showModal}>
              #{jobId}
              <Icon slug="arrow_vertical" />
            </Button>

            <div className={styles.dateBlock}>
              {dayjs(createdAt).format('HH : mm')}
              <span className={styles.separator}>•</span>
              {dayjs(createdAt).format('DD/MM/YY')}
            </div>

            {member && (
              <div className={styles.member}>
                <div className={styles.picture}>{member.firstname[0]}</div>
                <p className={styles.name}>{member.firstname}</p>
              </div>
            )}
          </div>

          <ProgressBar status="in_progress" />
        </div>
      </div>

      <JobDrawer
        workspaceId={workspaceId}
        name={name}
        jobId={jobId}
        jobData={jobData}
        prodImage={prodImage}
        targetDate={targetDate}
        stageId={stageId}
        assignedTo={assignedTo}
        comments={comments}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        _id={_id}
        bitrixId={externalId?.bitrixId}
      />
    </>
  );
};

export default observer(JobColumnItem);
