import { Collapse, Dropdown, Menu, message, Modal, Radio, RadioChangeEvent, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';

import { FC, useEffect, useState } from 'react';
import { Task } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../../utils';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import ProgressBar from '../../../ui/ProgressBar';
import SliderButton from '../../SliderButton';
import Timer from '../../Timer';
import ImageUpload from './ImageUpload';
import styles from './index.module.scss';
import { config } from '../../../../config';

const TaskItem: FC<Task> = ({
  status,
  dueDate,
  name,
  _id,
  title,
  onClick,
  description,
  assignedTo,
  comments,
  stageName,
  targetDate,
  workspaceId,
  expectedDate,
  jobId,
  isSelectedTask = false,
  jobIdShort,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState('');

  const showModal = () => {
    setSelectedTaskId(_id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setSelectedStatus(e.target.value);
    // console.log(e.target.value, 'Dfdfdf');
  };

  useEffect(() => {
    setSelectedTaskId(_id);
  }, []);

  const onChangeStatus = async () => {
    console.log(selectedStatus, workspaceId, selectedTaskId, 'completed');
    await StagesStore.updateTask(workspaceId, selectedTaskId, 'completed');
    if (StagesStore.taskUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      setIsModalOpen(false);
      StagesStore.setLayoutPage('library');
      StagesStore.getMyTasks(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const handleDateChange = async (value: Dayjs | null) => {
    if (!value) return;
    const formattedDate = value.toISOString();
    await StagesStore.updateTaskTargetDate(workspaceId, _id, formattedDate);
    console.log('updateTaskTargetDate');
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.setLayoutPage('library');
      StagesStore.getMyTasks(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  // console.log(targetDate);

  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedBitrixId, setIsCopiedBitrixId] = useState(false);

  const textToCopy = jobId;
  //const textToCopybitrix = bitrixId2 && bitrixId2;

  const handleCopy = async () => {
    // if (jobId !== undefined) {
    //   try {
    //     await navigator.clipboard.writeText(textToCopy);
    //     setIsCopied(true);
    //     message.info('Скопировано', 2);
    //     setTimeout(() => setIsCopied(false), 2000);
    //   } catch (err) {
    //     console.error('Failed to copy text: ', err);
    //   }
    // } else {
    //   console.error('Bitrix ID is undefined');
    // }
  };
  const handleCopyBitrixId = async () => {
    // if (bitrixId2 !== undefined) {
    //   try {
    //     await navigator.clipboard.writeText(bitrixId2);
    //     setIsCopiedBitrixId(true);
    //     message.info('Bitrix ID скопировано', 2);
    //     setTimeout(() => setIsCopiedBitrixId(false), 2000);
    //   } catch (err) {
    //     console.error('Failed to copy Bitrix ID: ', err);
    //   }
    // } else {
    //   console.error('Bitrix ID is undefined');
    // }
  };

  const workid = '66e170b52fc0a03d58789c24';

  useEffect(() => {
    if (!jobId) {
      return;
    }
    StagesStore.getJobsId(workid, jobId ? jobId : '').catch((e) => {
      console.error(e);
    });
  }, [jobId]);

  const ExpandIcon = () => (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.65338 3.11655L13.8068 9.26993L15 8.07668L7.65338 0.730064L0.306758 8.07668L1.5 9.26993L7.65338 3.11655Z"
        fill="#41474E"
      />
    </svg>
  );
  const [selectedMinutes, setSelectedMinutes] = useState<number | null>(0);
  const [targetDateTimer, setTargetDateTimer] = useState<number>(dayjs(expectedDate).valueOf());
  const [expDateNew, setEexpDateNew] = useState<string>('');

  const handleMinutesClick = (minutes: number) => {
    setSelectedMinutes(minutes);
  };

  useEffect(() => {
    const targetDateTimer = dayjs(expDateNew ? expDateNew : expectedDate).valueOf();
    setTargetDateTimer(targetDateTimer);
  }, [selectedMinutes, expectedDate, expDateNew]);

  const handleAddClick = () => {
    if (selectedMinutes !== null) {
      const newDate = dayjs(expectedDate).add(selectedMinutes, 'minute');
      console.log('Добавлено:', newDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
      setEexpDateNew(newDate.toISOString());
      changeExpextDate();
    }
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="15"
        onClick={() => {
          handleMinutesClick(15);
        }}
      >
        <input type="radio" checked={selectedMinutes === 15} readOnly /> 15 минут
      </Menu.Item>
      <Menu.Item
        key="25"
        onClick={() => {
          handleMinutesClick(25);
        }}
      >
        <input type="radio" checked={selectedMinutes === 25} readOnly /> 25 минут
      </Menu.Item>
      <Menu.Item
        key="45"
        onClick={() => {
          handleMinutesClick(45);
        }}
      >
        <input type="radio" checked={selectedMinutes === 45} readOnly /> 45 минут
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="add">
        <Button variant="primary" onClick={handleAddClick}>
          Добавить
        </Button>
      </Menu.Item>
    </Menu>
  );

  const changeExpextDate = async () => {
    const newDate = dayjs(expectedDate).add(selectedMinutes || 0, 'minute');
    const dateWithDuration = newDate.toISOString();

    await StagesStore.updateTaskexpectedDate(workspaceId, _id, dateWithDuration);
    if (StagesStore.taskUpdateDateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      console.log('expectedDate', expectedDate);
      StagesStore.getMyTaskOpen(workid, _id).catch((e) => {
        console.error(e);
      });
    }
  };

  return (
    <>
      {isSelectedTask == false ? (
        <div
          onClick={() =>
            onClick({
              status,
              dueDate,
              name,
              title,
              jobId,
              _id,
              description,
              assignedTo,
              comments,
              stageName,
              expectedDate,
            })
          }
          className={$class(styles.task_item, [styles.borderless])}
        >
          <div className={styles.flex_bl}>
            <div className={styles.flex_b}>
              <p className={styles.name}>{title}</p>
              <div className={styles.add_text}>
                <p className={styles.id}>
                  #{jobIdShort ? jobIdShort : _id.slice(-4)} <Icon slug="arrow_vertical" />
                </p>
                <div className={styles.dateBlock}>
                  {dayjs(dueDate).format('HH : mm')}
                  <span className={styles.separator}>•</span>
                  {dayjs(dueDate).format('DD/MM/YY')}
                </div>
              </div>
            </div>

            {status !== 'new' && <ProgressBar status="in_progress" />}
          </div>
        </div>
      ) : (
        <div className={styles.jobid}>
          <div className={styles.flex_jobid}>
            <p>Job ID:</p>
            <span>
              {jobIdShort}
              <Button
                variant="outlined"
                icon="copy_icon"
                onClick={handleCopy}
                children={undefined}
              />
            </span>
          </div>
          <div className={styles.flex_jobid}>
            <p>Bitrix Id:</p>
            <span>
              {StagesStore?.dataJobs?.job?.externalId?.bitrixId}
              <Button
                variant="outlined"
                icon="copy_icon"
                onClick={handleCopyBitrixId}
                children={undefined}
              />
            </span>
          </div>

          <div className={styles.colasp_bl}>
            <Space direction="vertical">
              <Collapse
                expandIconPosition="right"
                expandIcon={() => <ExpandIcon />}
                collapsible="header"
                defaultActiveKey={['1']}
                items={[
                  {
                    key: '1',
                    label: 'ФОТО ГОТОВОГО ПРОДУКТА',
                    children: (
                      <div className={styles.flex_timer}>
                        {jobId && <ImageUpload jobId={jobId} workspaceId={workspaceId} />}
                        <div className={styles.timer_date}>
                          <p>До завершения осталось:</p>
                          <div className={styles.flex_timer_cont}>
                            <Timer targetDateTimer={targetDateTimer} />{' '}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Dropdown overlay={menu}>
                                <a onClick={(e) => e.preventDefault()}>
                                  <Icon slug="icons_add_time" />
                                </a>
                              </Dropdown>

                              <button
                                onClick={() => {
                                  // changeExpextDate();
                                }}
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
              {StagesStore?.dataJobs?.job?.jobData &&
              StagesStore?.dataJobs?.job?.jobData?.[0]?.photoFiles?.length > 0 ? (
                <Collapse
                  expandIconPosition="right"
                  collapsible="header"
                  expandIcon={() => <ExpandIcon />}
                  defaultActiveKey={['2']}
                  items={[
                    {
                      key: '2',
                      label: 'Референс',
                      children: (
                        <>
                          <div className={styles.img_parent}>
                            {StagesStore?.dataJobs?.job?.jobData?.[0]?.photoFiles?.map(
                              (file, index) => (
                                <div key={index} className={styles.one_img}>
                                  <img src={`${config.EXTERNAL_ADDRESS}/public/${file}`} />
                                </div>
                              )
                            )}
                          </div>
                          <p className={styles.text_jobdata}>
                            {StagesStore?.dataJobs?.job?.jobData?.[0]?.text}
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
              ) : (
                ''
              )}
              <Collapse
                expandIconPosition="right"
                expandIcon={() => <ExpandIcon />}
                collapsible="icon"
                defaultActiveKey={['3']}
                items={[
                  {
                    key: '3',
                    label: 'сроки',
                    children: (
                      <>
                        <div className={styles.date_bl}>
                          <div className={styles.flex_jobid}>
                            <p>Цель</p>
                            <div className={styles.dateBlock}>
                              {dayjs(dueDate).format('HH : mm')}
                              <span className={styles.separator}>•</span>
                              {dayjs(dueDate).format('DD/MM/YY')}
                            </div>
                          </div>
                          <div className={styles.flex_jobid}>
                            <p>Прогноз</p>
                            <div className={styles.dateBlock}>
                              {dayjs(expDateNew ? expDateNew : expectedDate).format('HH : mm')}
                              <span className={styles.separator}>•</span>
                              {dayjs(expDateNew ? expDateNew : expectedDate).format('DD/MM/YY')}
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              {StagesStore?.dataJobs?.job?.comments &&
                StagesStore?.dataJobs?.job?.comments.length > 0 && (
                  <Collapse
                    expandIconPosition="right"
                    expandIcon={() => <ExpandIcon />}
                    collapsible="icon"
                    defaultActiveKey={['4']}
                    items={[
                      {
                        key: '4',
                        label: 'Комментарии',
                        children: (
                          <>
                            <div className={styles.commentsWrap}>
                              {StagesStore?.dataJobs?.job?.comments?.map((comment) => (
                                <div className={styles.commments}>
                                  <div className={styles.dateBlock}>
                                    {dayjs(comment.createdAt).format('HH : mm')}
                                    <span className={styles.separator}>•</span>
                                    {dayjs(comment.createdAt).format('DD/MM/YY')}
                                  </div>
                                  <p>{comment.text}</p>

                                  {comment.fileUrls.length > 0 &&
                                    comment.fileUrls?.map((phot) => (
                                      <div className={styles.link}>
                                        <p className={styles.linkk}>
                                          <Button
                                            variant="text"
                                            icon="arrow_vertical"
                                            autoWidth
                                            onClick={() =>
                                              window.open(
                                                `${config.EXTERNAL_ADDRESS}/public/${phot}`,
                                                '_blank'
                                              )
                                            }
                                          >
                                            Фото
                                          </Button>
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                )}
            </Space>
          </div>
          {/* <Button variant="primary" className="mt-8" onClick={showModal}>
            завершить
          </Button> */}
          <SliderButton name={'Завершить'} onClick={onChangeStatus} />
        </div>
      )}

      <Modal
        title="Обновление статуса"
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        width={350}
        onCancel={handleOk}
      >
        <p className={styles.name}>{title}</p>
        {stageName && <p>{stageName}</p>}
        <Radio.Group onChange={onChange} className={'pt-24 pb-24'} value={value}>
          <Space direction="vertical">
            <Radio value={'completed'}>completed</Radio>
          </Space>
        </Radio.Group>
        <Button variant="primary" className="mt-8" onClick={onChangeStatus}>
          сохранить
        </Button>
      </Modal>
    </>
  );
};

export default observer(TaskItem);
