import styles from './index.module.scss';
import { Collapse, CollapseProps } from 'antd';
import React, { FC, ReactNode } from 'react';
import dayjs from 'dayjs';

interface IProps {
  expandIcon: (panelProps: any) => ReactNode;
  targetDate?: string;
}

const DeadlineBlock: FC<IProps> = ({ expandIcon, targetDate }) => {
  const deadlineCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'СРОКИ',
      children: (
        <div className={styles.fieldsWrap}>
          <div className={styles.field}>
            <p className={styles.name}>Цель</p>
            <div className={styles.copyBlock}>
              {dayjs(targetDate).format('DD / MM / YYYY, HH:mm')}
            </div>
            {/*<DatePicker*/}
            {/*  showTime*/}
            {/*  allowClear={false}*/}
            {/*  suffixIcon={null}*/}
            {/*  variant="filled"*/}
            {/*  format="DD / MM / YYYY, HH:mm"*/}
            {/*  value={dayjs(targetDate)}*/}
            {/*  onChange={handleDateChange}*/}
            {/*  style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
            {/*/>*/}
          </div>
          {/*<div className={styles.field}>*/}
          {/*  <p className={styles.name}>Прогноз</p>*/}
          {/*  <DatePicker*/}
          {/*    showTime*/}
          {/*    allowClear={false}*/}
          {/*    suffixIcon={null}*/}
          {/*    variant="filled"*/}
          {/*    format="DD / MM / YYYY, HH:mm"*/}
          {/*    style={{ backgroundColor: '#F3F5F6E2', borderRadius: '8px', padding: '4px 8px' }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      ),
    },
  ];

  return (
    <Collapse
      items={deadlineCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      ghost
    />
  );
};

export default DeadlineBlock;
