// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UGwUAroEUydch6Cu1i0X{font-weight:500;border-bottom:2px dashed #d2d2d2;font-size:32px;font-family:"Inter",sans-serif;color:#000}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/Timer/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gCAAA,CACA,cAAA,CACA,8BAAA,CACA,UAAA","sourcesContent":[".timer_bl {\n  font-weight: 500;\n  border-bottom: 2px dashed #d2d2d2;\n  font-size: 32px;\n  font-family: 'Inter', sans-serif;\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer_bl": `UGwUAroEUydch6Cu1i0X`
};
export default ___CSS_LOADER_EXPORT___;
