import { EditOutlined } from '@ant-design/icons';
import { Form, Input, Space, message } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import usePageTitle from '../../DataSource/Hooks/usePageTitle';
import { AuthStore } from '../../DataSource/Stores/AuthStore';
import { ProfileStore } from '../../DataSource/Stores/Site/ProfileStore';
import Lang from '../Lang/index';
import Button from '../ui/Button';
import ModalProfile from './ModalProfile';
import styles from './Profile.module.scss';

export type FieldTypetwo = {
  firstname: string;
  secondname: string;
  password: string;
  email: string;
  confirmpassword: string;
  token?: string;
};

const Profile: React.FC = () => {
  usePageTitle(Lang({ str: 'profile', section: 'default' }));

  const [form] = Form.useForm();
  const [passReq, setPassReq] = useState<boolean>(false);
  const [confirmPassword1, setConfirmPassword1] = useState('');
  const [confirmPassword2, setConfirmPassword2] = useState('');

  useEffect(() => {
    console.log('Profile');
    // Установка начальных значений формы при монтировании компонента
    form.setFieldsValue(AuthStore.currentUserData.user);
  }, []);

  const forceUpdate = () => {
    form.setFieldsValue({ email: AuthStore.currentUserData.user.email });
  };

  const onFinish = async (values: FieldTypetwo) => {
    console.log(values);

    if (values.password && values.password !== values.confirmpassword) {
      message.error('Passwords do not match');
      console.error('Passwords do not match');
      return; // Прерываем отправку формы
    }

    try {
      await AuthStore.editProfile(values);
    } catch (error) {
      message.error('Error');
      return;
    }
    message.info('Success');
  };
  const validator = async (rule: any, value: any) => {
    console.log('validator|value=', value);
    const passwordFieldValue = form.getFieldValue('password');
    if (!value && !passwordFieldValue) {
      setPassReq(false);
      return;
    }
    setPassReq(true);
    if (value !== passwordFieldValue) {
      return 'Passwords do not match';
    }
  };
  const showModal = () => {
    ProfileStore.isModalOpen = true;
  };
  const handleConfirmPasswordChange1 = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setConfirmPassword1(value);
    setPassReq(!!confirmPassword1 || !!confirmPassword2);
  };
  const handleConfirmPasswordChange2 = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setConfirmPassword2(value);
    setPassReq(!!confirmPassword1 || !!confirmPassword2);
  };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className="p-20">
      {/* <h2 className={styles.title}>{Lang({ str: 'profile', section: 'default' })}</h2> */}
      <div className="card_body">
        <Form
          key={Date.now()}
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          // initialValues={AuthStore.currentUserData.user}
          onFinish={onFinish}
          //onFinishFailed={onFinishCreateFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item<FieldTypetwo>
            label={Lang({ str: 'firstname', section: 'default' })}
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
              {
                message: 'You can only enter letters',
                pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item<FieldTypetwo>
            label={Lang({ str: 'secondname', section: 'default' })}
            name="secondname"
            rules={[
              { required: true, message: 'Please input your second name!' },
              {
                message: 'You can only enter letters',
                pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Space direction="horizontal">
            <Form.Item<FieldTypetwo>
              label={Lang({ str: 'email', section: 'default' })}
              name="email"
            >
              <Input disabled />
            </Form.Item>
            <Button className="mt-15" onClick={showModal}>
              <EditOutlined />
            </Button>
          </Space>
          <Form.Item<FieldTypetwo>
            label="Password"
            name="password"
            rules={[{ message: 'Please input your password!' }]}
          >
            <Input.Password onChange={handleConfirmPasswordChange1} />
          </Form.Item>
          <Form.Item<FieldTypetwo>
            label="Confirm password"
            name="confirmpassword"
            rules={[{ required: passReq, message: 'Please confirm password!' }, { validator }]}
          >
            <Input.Password onChange={handleConfirmPasswordChange2} />
          </Form.Item>

          <Form.Item>
            <Button icon={'bee'} className={styles.button_prime} variant="primary">
              {Lang({ str: 'save', section: 'default' })}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ModalProfile forceUpdate={forceUpdate} />
    </div>
  );
};
export default observer(Profile);
