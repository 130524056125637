import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface TimerProps {
  targetDateTimer: any;
}

const Timer: React.FC<TimerProps> = ({ targetDateTimer }) => {
  const [timeRemaining, setTimeRemaining] = useState<string>('');

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date().getTime();

      const distance = targetDateTimer - now;

      if (distance < 0) {
        setTimeRemaining('Время вышло!');
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeRemaining(`${days}д ${hours}ч ${minutes}м ${seconds}с`);
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [targetDateTimer]);

  return <div className={styles.timer_bl}>{timeRemaining}</div>;
};

export default observer(Timer);
