import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { WorkspacesItemProps } from '../../../components/Cabinet/Main';
import { basicRequestDelete } from '../../Requests/basicRequestDelete';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestPost } from '../../Requests/basicRequestPost';
import { api, RequestData } from '../../../utils';
import { InWorkJobs, JobsDataType, Member } from '../../../components/ types';

export type IWorkspace = {
  _id: string;
  name: string;
  category: string;
  theme: string | Record<string, string>;
  invites: [];
  members: [];
};

export type IWorkspaceCreate = {
  name: string;
};

export class WorkspacesStoreActionsClass {
  @observable modalSettingOpen: boolean = false;
  @observable modalSettingWorkspaceId: string | null = null;

  @observable nameModal: string = '';

  @observable isModalOpenAdd: boolean = false;

  @observable loadingError: boolean = false;
  @observable addError: boolean = false;

  @observable loading: boolean = false;
  @observable addloading: boolean = false;

  @observable getloading: boolean = false;
  @observable getError: boolean = false;

  @observable dataListWork: IWorkspace[] = [];

  @observable data: WorkspacesItemProps = {};
  @observable members: Record<string, Member> = {};
  @observable inWorkJobs: InWorkJobs = {};

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  @action setMembers(members: Member[]) {
    for (const member of members) {
      this.members[member._id] = member;
    }
  }

  @action setInWorkJobs(inWorkJobs: { inWorkJobs: InWorkJobs }) {
    this.inWorkJobs = inWorkJobs.inWorkJobs;
  }

  @action
  modalSettingShow(workspaceId: string) {
    this.modalSettingWorkspaceId = workspaceId;
    this.modalSettingOpen = true;
  }

  @action
  modalSettingClose() {
    this.modalSettingWorkspaceId = null;
    this.modalSettingOpen = false;
  }

  @action
  async addWorkspaces(name: string) {
    console.log('addWorkspaces');
    this.addloading = true;
    this.addError = false;
    try {
      await basicRequestPost('/workspaces', { name });
    } catch (error) {
      console.error('addWorkspaces|error=', error);
      this.addError = true;
    } finally {
      this.addloading = false;
    }
  }

  @action
  async getWorkspaces(_id: string, options?: { hiddenLoading: boolean }) {
    const hiddenLoading = options?.hiddenLoading !== undefined ? options.hiddenLoading : false;

    console.log('getWorkspacesId');

    if (!hiddenLoading) {
      this.getloading = true;
      this.data = {};
    }

    this.getError = false;

    try {
      const res = await basicRequestGet('/workspaces/' + _id);
      runInAction(() => {
        this.data = res.data;
      });
    } catch (error) {
      console.error('getWorkspacesId|error=', error);
      this.getError = true;
    } finally {
      runInAction(() => {
        this.getloading = false;
      });
    }
  }

  @action
  async getList() {
    console.log('getList workspaces');
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.loadingError = false;
    this.dataListWork = [];
    try {
      const res = await basicRequestGet('/workspaces');
      console.log('---- ww|getList|res.data=', res.data);
      runInAction(() => {
        this.dataListWork = res.data.data as IWorkspace[];
      });
    } catch (error) {
      console.error('getList workspaces|error=', error);
      this.loadingError = true;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async deleteWorkspaces(_id: string) {
    const index = this.dataListWork.findIndex((obj) => obj._id === _id);
    console.log('deleteChallenge|index=', _id);
    if (index === -1) {
      console.error('deleteChallenge|index !== -1');
      throw 'Remove error';
    }
    await basicRequestDelete('/workspaces/' + _id);
    this.dataListWork = this.dataListWork.filter((obj) => obj._id !== _id);
    if (this.data?._id === _id) {
      this.data = {};
    }
  }

  workspaces = new RequestData<[], IWorkspace[]>(async () => await api('workspaces'), []);

  createWorkspace = new RequestData<[IWorkspaceCreate], IWorkspace>(
    async (params) => await api.post('workspaces', { ...params })
  );

  workspaceById = new RequestData<[string], IWorkspace>(
    async (workspaceId) => await api(`workspaces/${workspaceId}`),
    null
  );

  inviteAdd = new RequestData<[string, string], IWorkspace>(
    async (workspaceId: string, email: string) =>
      await api.post(`workspaces/${workspaceId}/invites`, { workspaceId, email })
  );

  inviteDel = new RequestData<[string, string], IWorkspace>(
    async (workspaceId: string, _id: string) =>
      await api.delete(`workspaces/${workspaceId}/invites/${_id}`)
  );

  inviteReSend = new RequestData<[string, string], IWorkspace>(
    async (workspaceId: string, _id: string) =>
      await api.get(`workspaces/${workspaceId}/invites/${_id}/emailSend`)
  );

  membersGet = new RequestData<[string], Member[]>(
    async (workspaceId: string) => await api.get(`/workspaces/${workspaceId}/members`)
  );

  memberDel = new RequestData<[string, string], IWorkspace>(
    async (workspaceId: string, _id: string) =>
      await api.delete(`/workspaces/${workspaceId}/members/${_id}`)
  );

  getJobsById = new RequestData<[string, string], JobsDataType[]>(
    async (workspaceId: string, jobId: string) =>
      await api.get(`/workspaces/${workspaceId}/jobs/findByJobId/${jobId}`)
  );

  getJobsInWork = new RequestData<[string], { inWorkJobs: InWorkJobs }>(
    async (workspaceId: string) => await api.get(`/workspaces/${workspaceId}/jobs/inWork`)
  );
}
