import {
  ChangeEvent,
  ChangeEventHandler,
  CSSProperties,
  forwardRef,
  HTMLInputTypeAttribute,
  useState,
} from 'react';
import { $class } from '../../../utils';
import Icon from '../Icon';
import './index.scss';

interface ValidationRule {
  validator: (value: string) => boolean;
  message: string;
}

interface IProps {
  value?: string | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  name?: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  width?: string;
  style?: CSSProperties;
  icon?: string;
  validationRules?: ValidationRule[];
}

const Input = forwardRef<HTMLInputElement, IProps>(
  ({
    value,
    onChange,
    className,
    maxLength,
    disabled,
    name,
    type,
    placeholder = '',
    width,
    style,
    icon,
    validationRules,
    ...rest
  }) => {
    const [err, setErr] = useState<string | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (onChange) onChange(e);

      if (validationRules)
        for (const rule of validationRules) {
          if (!rule.validator(newValue)) {
            setErr(rule.message);
            return;
          }
        }
      setErr(null);
    };

    return (
      <div className="ui-input-wrap">
        <div
          className={$class('ui-input', ['ui-input--disabled', disabled], className)}
          style={{ width: width ? width : 'auto' }}
        >
          {icon && (
            <div className="ui-input-icon">
              <Icon slug={icon} />
            </div>
          )}
          <input
            required
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            readOnly={disabled}
            type={type}
            style={{ padding: icon ? '16px 42px 16px 12px' : '16px 12px' }}
            maxLength={maxLength}
            {...rest}
          />
        </div>
        {err && <div className="ui-input-error">{err}</div>}
      </div>
    );
  }
);

export default Input;
