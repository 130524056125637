import { message, Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, Fragment, useEffect } from 'react';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import uWS from '../../../uWS';
import JobColumnItem from './JobColumnItem';
import styles from './index.module.scss';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import JobColumn from './JobColumn';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface IProps {
  view: 'list' | 'columns';
  workid: string;
}

const NewJobsBlock: FC<IProps> = ({ view, workid }) => {
  useEffect(() => {
    if (workid) {
      StagesStore.getList(workid).catch((e) => {
        console.error(e);
      });
    }
  }, [workid]);

  useEffect(() => {
    StagesStore.getListJobs(workid).catch((e) => {
      console.error(e);
    });
  }, [workid]);

  useEffect(() => {
    if (!workid) return;
    const eventStatus = uWS.subscribe(`workspaces/${workid}/changeJob`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace changeJob:', data.error);
        return;
      }

      console.log('changeJob:', data);
      StagesStore.getListJobs(workid).catch((e) => {
        console.error(e);
      });
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${workid}/changeJob`);
    };
  }, [workid]);

  const onDragEnd = async (e: DragEndEvent) => {
    const { active, over } = e;
    if (!over) return;
    if (active.data.current?.stageId === over.id) return;
    // if (active.data.current?.sortable.containerId === over.data.current?.sortable.containerId) {
    //   if (active.id === over.id) return;
    //
    //   //
    //   return;
    // }

    await StagesStore.updateJobStage(
      workid,
      active.id as string,
      // over.data.current?.stageId as string
      over.id as string
    );
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.isModalOpen = false;
      StagesStore.getListJobs(workid).catch((e) => {
        console.error(e);
      });
    }
  };

  return (
    <div className={styles.newJobsBlock}>
      <div className={styles.newJobsBlockBody}>
        {view === 'columns' && (
          <div className={styles.columns}>
            {StagesStore.loadingError && 'Error loading'}
            {StagesStore.loading ? (
              <Spin size="small" />
            ) : StagesStore.dataList.length === 0 ? (
              <div className={styles.no_project}>
                <Result status="warning" title="You don't have any stages" />
              </div>
            ) : (
              <DndContext onDragEnd={onDragEnd}>
                {StagesStore.dataList.map((stage) => {
                  const filteredJobs = StagesStore.dataJobsList
                    ?.filter((job) => job.stageId === stage._id)
                    .sort(
                      (a: any, b: any) =>
                        new Date(b.targetDate).getTime() - new Date(a.targetDate).getTime()
                    ); // Сортировка по createdAt в обратном порядке

                  return (
                    <Fragment key={stage._id}>
                      <JobColumn {...stage}>
                        {StagesStore.loadingJobsError && 'Error loading'}
                        {StagesStore.loadingJobs && !StagesStore.dataJobsList?.length ? (
                          <Spin size="small" />
                        ) : StagesStore.dataJobsList?.length === 0 ? (
                          <div>No jobs for this stage</div>
                        ) : (
                          <SortableContext
                            items={filteredJobs.map((item) => item._id)}
                            strategy={verticalListSortingStrategy}
                          >
                            {filteredJobs.map((job) => (
                              <JobColumnItem key={job._id} {...job} />
                            ))}
                          </SortableContext>
                        )}
                      </JobColumn>
                    </Fragment>
                  );
                })}
              </DndContext>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(NewJobsBlock);
