import { FC, ReactNode } from 'react';
import { $class } from '../../../utils';
import Clickable from '../Clickable';
import Icon from '../Icon';
import './index.scss';

type ButtonVariant = 'primary' | 'secondary' | 'outlined' | 'text';

interface IProps {
  children: ReactNode;
  className?: string;
  variant?: ButtonVariant;
  icon?: string;
  loading?: boolean;
  width?: number;
  autoWidth?: boolean;
  onClick?: () => void;
}

const Button: FC<IProps> = ({
  children,
  className,
  variant = 'primary',
  icon,
  loading,
  width,
  autoWidth = false,
  onClick,
}) => {
  return (
    <Clickable
      className={$class(
        'ui-button',
        ['ui-button--primary', variant === 'primary'],
        ['ui-button--secondary', variant === 'secondary'],
        ['ui-button--outlined', variant === 'outlined'],
        ['ui-button--text', variant === 'text'],
        ['ui-button--loading', loading],
        className
      )}
      onClick={onClick}
      style={{ width: width ? width : autoWidth ? 'fit-content' : '100%' }}
    >
      <div className="ui-button-wrap">
        {children}
        {icon && <Icon slug={icon} />}
      </div>
      <div className="ui-button-loading">
        <span></span>
      </div>
    </Clickable>
  );
};

export default Button;
