import type { RadioChangeEvent } from 'antd';
import { Collapse, message, Modal, Radio, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { JobsDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../../utils';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';

import SliderButton from '../../SliderButton';
import styles from './index.module.scss';
import { config } from '../../../../config';

interface JobItemProps extends JobsDataType {
  onClick: (job: JobsDataType) => void;
  isSelectedJob: boolean;
}

const JobItem: FC<JobItemProps> = ({
  name,
  _id,
  onClick,
  jobId,
  createdAt,
  targetDate,
  workspaceId,
  bitrixId2,
  comments,
  jiraId2,
  jobData,
  isSelectedJob = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStages, setSelectedStages] = useState('');
  const [selectedJobId, setSelectedJobId] = useState('');

  const showModal = () => {
    setIsModalOpen(true);
    setSelectedJobId(_id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setSelectedStages(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    setSelectedJobId(_id);
  }, []);

  const onChangeStages = async () => {
    //console.log(selectedJobId, selectedStages, workspaceId);
    await StagesStore.updateJobStage(workspaceId, selectedJobId, '66e194a01725b77d117c4d5a');
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      setIsModalOpen(false);
      StagesStore.setLayoutPageJob('joblay');
      StagesStore.setTabsIndex(1);
      // StagesStore.getListJobsQueue(workspaceId, '66e194841725b77d117c4d57').catch((e) => {
      //   console.error(e);
      // });
    }
  };
  const dateCreate = dayjs(targetDate);

  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedBitrixId, setIsCopiedBitrixId] = useState(false);

  const textToCopy = jobId;
  const textToCopybitrix = bitrixId2 && bitrixId2;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      message.info('Скопировано', 2);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const handleCopyBitrixId = async () => {
    if (bitrixId2 !== undefined) {
      try {
        await navigator.clipboard.writeText(bitrixId2);
        setIsCopiedBitrixId(true);
        message.info('Bitrix ID скопировано', 2);
        setTimeout(() => setIsCopiedBitrixId(false), 2000);
      } catch (err) {
        console.error('Failed to copy Bitrix ID: ', err);
      }
    } else {
      console.error('Bitrix ID is undefined');
    }
  };

  const ExpandIcon = () => (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.65338 3.11655L13.8068 9.26993L15 8.07668L7.65338 0.730064L0.306758 8.07668L1.5 9.26993L7.65338 3.11655Z"
        fill="#41474E"
      />
    </svg>
  );

  return (
    <>
      {isSelectedJob == false ? (
        <div
          onClick={() =>
            onClick({ name, _id, jobId, workspaceId, bitrixId2, jiraId2, comments, jobData })
          }
          className={$class(styles.jobColumnItem, [styles.bjobless])}
        >
          <div className={styles.flex_bl}>
            <p className={styles.name}>{name}</p>
            <div className={styles.add_text}>
              <p className={styles.id}>
                #{jobId} <Icon slug="arrow_vertical" />
              </p>
              <div className={styles.dateBlock}>
                {dayjs(targetDate).format('HH : mm')}
                <span className={styles.separator}>•</span>
                {dayjs(targetDate).format('DD/MM/YY')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className={styles.name2}>{name}</p>
          <div className={styles.jobid}>
            <div className={styles.flex_jobid}>
              <p>Job ID:</p>
              <span>
                {jobId}
                <Button
                  variant="outlined"
                  icon="copy_icon"
                  onClick={handleCopy}
                  children={undefined}
                />
              </span>
            </div>
            <div className={styles.flex_jobid}>
              {bitrixId2 ? <p>Bitrix Id:</p> : <p>Jira Id:</p>}
              <span>
                {bitrixId2 ? bitrixId2 : jiraId2}
                <Button
                  variant="outlined"
                  icon="copy_icon"
                  onClick={handleCopyBitrixId}
                  children={undefined}
                />
              </span>
            </div>
            <div className={styles.colasp_bl}>
              <Space direction="vertical">
                {jobData && jobData?.[0]?.photoFiles?.length > 0 ? (
                  <Collapse
                    expandIconPosition="right"
                    collapsible="header"
                    defaultActiveKey={['1']}
                    expandIcon={() => <ExpandIcon />}
                    items={[
                      {
                        key: '1',
                        label: 'референс',
                        children: (
                          <>
                            <div className={styles.img_parent}>
                              {jobData?.[0]?.photoFiles?.map((file, index) => (
                                <div key={index} className={styles.one_img}>
                                  <img src={`${config.EXTERNAL_ADDRESS}/public/${file}`} />
                                </div>
                              ))}
                            </div>
                            <p className={styles.text_jobdata}>{jobData[0]?.text}</p>
                          </>
                        ),
                      },
                    ]}
                  />
                ) : (
                  ''
                )}
                <Collapse
                  expandIconPosition="right"
                  collapsible="icon"
                  expandIcon={() => <ExpandIcon />}
                  defaultActiveKey={['2']}
                  items={[
                    {
                      key: '2',
                      label: 'сроки',
                      children: (
                        <>
                          <div className={styles.date_bl}>
                            <div className={styles.flex_jobid}>
                              <p>Цель</p>
                              <div className={styles.dateBlock}>
                                {dayjs(targetDate).format('HH : mm')}
                                <span className={styles.separator}>•</span>
                                {dayjs(targetDate).format('DD/MM/YY')}
                              </div>
                            </div>
                            {/* <div className={styles.flex_jobid}>
                                <p>Прогноз</p>
                                <div className={styles.dateBlock}>
                                  {dayjs(createdAt).format('HH : mm')}
                                  <span className={styles.separator}>•</span>
                                  {dayjs(createdAt).format('DD/MM/YY')}
                                </div>
                              </div> */}
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
                {comments && comments.length > 0 && (
                  <Collapse
                    expandIconPosition="right"
                    collapsible="icon"
                    expandIcon={() => <ExpandIcon />}
                    defaultActiveKey={['3']}
                    items={[
                      {
                        key: '3',
                        label: 'Комментарии',
                        children: (
                          <>
                            <div className={styles.commentsWrap}>
                              {comments?.map((comment) => (
                                <div className={styles.commments}>
                                  <div className={styles.dateBlock}>
                                    {dayjs(comment.createdAt).format('HH : mm')}
                                    <span className={styles.separator}>•</span>
                                    {dayjs(comment.createdAt).format('DD/MM/YY')}
                                  </div>
                                  <p>{comment.text}</p>

                                  {comment.fileUrls.length > 0 &&
                                    comment.fileUrls?.map((phot) => (
                                      <div className={styles.link}>
                                        <p className={styles.linkk}>
                                          <Button
                                            variant="text"
                                            icon="arrow_vertical"
                                            autoWidth
                                            onClick={() =>
                                              window.open(
                                                `${config.EXTERNAL_ADDRESS}/public/${phot}`,
                                                '_blank'
                                              )
                                            }
                                          >
                                            Фото
                                          </Button>
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                )}
              </Space>
            </div>
          </div>
          {/* <Button className="mt-8" variant="primary" onClick={showModal}>
            завершить
          </Button> */}

          <SliderButton name={'Взять в работу'} onClick={onChangeStages} />
        </div>
      )}

      <Modal
        title="Выберите новый статус"
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        width={350}
        onCancel={handleOk}
      >
        <p>jobId: #{jobId}</p>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {StagesStore.dataList.map((stage) => (
              <Radio key={stage._id} value={stage._id}>
                {stage.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        <Button variant="primary" onClick={onChangeStages}>
          сохранить
        </Button>
      </Modal>
    </>
  );
};

export default observer(JobItem);
