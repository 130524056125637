import { animated, useSpring } from '@react-spring/web';
import { Dropdown, Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef } from 'react';
import { Task } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import Icon from '../../ui/Icon';
import styles from '../User/index.module.scss';
import TaskItem from './TaskItem';

const TaskTab: FC<Task> = () => {
  //const [showTextTask, setShowTextTask] = useState(false);

  const wrapRef = useRef<HTMLDivElement>(null);
  //const widthnew: any = wrapRef?.current?.clientWidth;
  const widthnew = wrapRef.current ? wrapRef.current.clientWidth : 0;

  const { x } = useSpring({
    x: StagesStore.layoutPage === 'library' ? 0 : -widthnew,
  });
  const workid = '66e170b52fc0a03d58789c24';

  const handleTaskItemClick = (task: Task) => {
    StagesStore.setShowTextTask(true);
    StagesStore.setLayoutPage('editor');
    StagesStore.setSelectedTask(task);
    StagesStore.setIsSelectedTask(true);
  };

  const onBack = () => {
    StagesStore.setShowTextTask(false);
    StagesStore.setLayoutPage('library');
    StagesStore.setIsSelectedTask(false);
  };

  useEffect(() => {
    if (!StagesStore.selectedTask?._id) return;
    StagesStore.getMyTaskOpen(workid, StagesStore.selectedTask?._id).catch((e) => {
      console.error(e);
    });
  }, [StagesStore.selectedTask]);

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showTextTask ? 1 : 0,
    },
    delay: 500, // Задержка перед появлением текста
    config: { duration: 400 }, // Длительность анимации
  });

  return (
    <div className={styles.prompt_wrap} ref={wrapRef}>
      <div className={styles.task_flex}>
        {StagesStore.layoutPage === 'library' ? (
          <div className={styles.task_child_flex}>
            {StagesStore.loadingErrorMyTasks && 'Error loading'}
            {StagesStore.loadingMyTasks ? (
              <Spin size="small" />
            ) : StagesStore.dataMyTasks?.length === 0 ? (
              <div className={styles.no_tasks}>
                <Result status="warning" title="У Вас пока нет задач" />
              </div>
            ) : (
              StagesStore.dataMyTasks?.map((task_i) => (
                <TaskItem
                  key={task_i._id}
                  _id={task_i._id}
                  onClick={handleTaskItemClick}
                  dueDate={task_i.dueDate || ''}
                  title={task_i.title || ''}
                  status={task_i.status || ''}
                  description={task_i.description || ''}
                  stageName={task_i.stageName || ''}
                  comments={task_i.comments || []}
                  workspaceId={workid}
                  jobId={task_i.jobId}
                  jobIdShort={task_i.jobIdShort}
                  expectedDate={task_i.expectedDate}
                  isSelectedTask={
                    StagesStore.isSelectedTask && StagesStore.selectedTask?._id === task_i._id
                  }
                />
              ))
            )}
          </div>
        ) : (
          <div className={styles.task_child_flex}>
            <div className={styles.prompts_create}>
              <div className={styles.flex_block}>
                <animated.p className={styles.back} onClick={onBack} style={textAnimation}>
                  <Icon slug="arrow_back" />
                  Все "В работе”
                </animated.p>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: <p>текст</p>,
                      },
                      {
                        key: '2',
                        danger: true,
                        label: <p>текст</p>,
                      },
                    ],
                  }}
                >
                  <a className="button_add" onClick={(e) => e.preventDefault()}>
                    <Icon slug="icons_menu_point" />
                  </a>
                </Dropdown>
              </div>

              {StagesStore.selectedTask && (
                <TaskItem
                  _id={StagesStore?.selectedTask?._id || ''}
                  dueDate={StagesStore?.selectedTask?.dueDate || ''}
                  title={StagesStore?.selectedTask?.title || ''}
                  status={StagesStore?.selectedTask?.status || ''}
                  description={StagesStore?.selectedTask?.description || ''}
                  stageName={StagesStore?.selectedTask?.stageName || ''}
                  onClick={() => {}}
                  comments={StagesStore?.selectedTask?.comments || []}
                  workspaceId={workid}
                  jobId={StagesStore?.selectedTask?.jobId}
                  jobIdShort={StagesStore?.selectedTask?.jobIdShort}
                  expectedDate={StagesStore?.selectedTask?.expectedDate}
                  isSelectedTask={StagesStore.isSelectedTask}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TaskTab);
