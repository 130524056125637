import React from 'react';
import { $class } from '../../../utils';

interface ICustomIcons {
  [key: string]: JSX.Element;
}

const customIcons: ICustomIcons = {
  bee: (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71875 3.28125V3.77344L1.2851 7.37877C0.781111 7.90796 0.5 8.62038 0.5 9.35117C0.5 10.9045 1.7592 12.1875 3.3125 12.1875C4.04974 12.1875 4.72072 11.9038 5.22226 11.4397C5.66417 12.584 6.38511 13.6158 7.33781 14.4324L8 15L8.66219 14.4324C9.61489 13.6158 10.3358 12.584 10.7777 11.4397C11.2793 11.9038 11.9503 12.1875 12.6875 12.1875C14.2408 12.1875 15.5 10.9045 15.5 9.35117C15.5 8.62038 15.2189 7.90796 14.7149 7.37877L11.2812 3.77344V3.28125C11.2812 1.46907 9.81218 0 8 0C6.18782 0 4.71875 1.46907 4.71875 3.28125ZM6.15836 9.375C6.30839 10.8032 6.95917 12.1368 8 13.1353C9.04083 12.1368 9.69161 10.8032 9.84165 9.375H6.15836ZM9.875 7.96875V6.09375H6.125V7.96875H9.875ZM6.125 4.6875H9.875V3.28125C9.875 2.24572 9.03553 1.40625 8 1.40625C6.96447 1.40625 6.125 2.24572 6.125 3.28125V4.6875ZM2.30342 8.3486L4.71875 5.8125V9.375C4.71875 10.1517 4.08915 10.7812 3.3125 10.7812C2.55124 10.7812 1.90625 10.1433 1.90625 9.35117C1.90625 8.97645 2.0519 8.61269 2.30342 8.3486ZM11.2812 9.375V5.8125L13.6966 8.3486C13.9481 8.61269 14.0938 8.97645 14.0938 9.35117C14.0938 10.1433 13.4488 10.7812 12.6875 10.7812C11.9108 10.7812 11.2812 10.1517 11.2812 9.375Z"
        fill="#2A3038"
      />
    </svg>
  ),
  activity: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2.5H3V7H4.53647L5.78647 4.5H7.73293L10.2329 10H10.2865L11.5365 7.5H14V2.5Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        d="M14 13.5V9H12.4635L11.2135 11.5H9.26707L6.76707 6H6.71353L5.46353 8.5H3V13.5H14Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1H15.5V15H1.5V1ZM3 2.5H14V7.5H11.5365L10.2865 10H10.2329L7.73293 4.5H5.78647L4.53647 7H3V2.5ZM14 9V13.5H3V8.5H5.46353L6.71353 6H6.76707L9.26707 11.5H11.2135L12.4635 9H14Z"
        fill="#2A3038"
      />
    </svg>
  ),
  cake: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7.5C3 7.22386 3.22386 7 3.5 7H13.5C13.7761 7 14 7.22386 14 7.5V9.25H3V7.5Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        d="M5.25 1C5.25 1.55228 4.80228 2 4.25 2C3.69772 2 3.25 1.55228 3.25 1C3.25 0.447715 3.69772 0 4.25 0C4.80228 0 5.25 0.447715 5.25 1Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.5H7.75V3.5H9.25V5.5H12V3.5H13.5V5.5C14.6046 5.5 15.5 6.39543 15.5 7.5V15H1.5V7.5C1.5 6.39543 2.39543 5.5 3.5 5.5V3.5H5V5.5ZM3 7.5C3 7.22386 3.22386 7 3.5 7H13.5C13.7761 7 14 7.22386 14 7.5V9.25H3V7.5ZM3 10.75V13.5H14V10.75H3Z"
        fill="#2A3038"
      />
      <path
        d="M8.5 2C9.05228 2 9.5 1.55228 9.5 1C9.5 0.447715 9.05228 0 8.5 0C7.94772 0 7.5 0.447715 7.5 1C7.5 1.55228 7.94772 2 8.5 2Z"
        fill="#2A3038"
      />
      <path
        d="M13.75 1C13.75 1.55228 13.3023 2 12.75 2C12.1977 2 11.75 1.55228 11.75 1C11.75 0.447715 12.1977 0 12.75 0C13.3023 0 13.75 0.447715 13.75 1Z"
        fill="#2A3038"
      />
    </svg>
  ),
  users: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 5C6.94036 5 7.5 4.44036 7.5 3.75C7.5 3.05964 6.94036 2.5 6.25 2.5C5.55964 2.5 5 3.05964 5 3.75C5 4.44036 5.55964 5 6.25 5Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 4.25C13.25 4.66421 12.9142 5 12.5 5C12.0858 5 11.75 4.66421 11.75 4.25C11.75 3.83579 12.0858 3.5 12.5 3.5C12.9142 3.5 13.25 3.83579 13.25 4.25Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11V13.5H10.5V9.5H13.5C14.3284 9.5 15 10.1716 15 11ZM9 9.5V13.5H2V11C2 10.1716 2.67157 9.5 3.5 9.5H9Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 6.5C7.76878 6.5 9 5.26878 9 3.75C9 2.23122 7.76878 1 6.25 1C4.73122 1 3.5 2.23122 3.5 3.75C3.5 5.26878 4.73122 6.5 6.25 6.5ZM6.25 5C6.94036 5 7.5 4.44036 7.5 3.75C7.5 3.05964 6.94036 2.5 6.25 2.5C5.55964 2.5 5 3.05964 5 3.75C5 4.44036 5.55964 5 6.25 5Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 4.25C14.75 5.49264 13.7426 6.5 12.5 6.5C11.2574 6.5 10.25 5.49264 10.25 4.25C10.25 3.00736 11.2574 2 12.5 2C13.7426 2 14.75 3.00736 14.75 4.25ZM13.25 4.25C13.25 4.66421 12.9142 5 12.5 5C12.0858 5 11.75 4.66421 11.75 4.25C11.75 3.83579 12.0858 3.5 12.5 3.5C12.9142 3.5 13.25 3.83579 13.25 4.25Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 8C15.1569 8 16.5 9.34315 16.5 11V15H0.5V11C0.5 9.34315 1.84315 8 3.5 8H13.5ZM15 11V13.5H10.5V9.5H13.5C14.3284 9.5 15 10.1716 15 11ZM9 9.5V13.5H2V11C2 10.1716 2.67157 9.5 3.5 9.5H9Z"
        fill="#2A3038"
      />
    </svg>
  ),
  sliders: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.5H7.5V5.5H5V2.5Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 10.5H12V13.5H9.5V10.5Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.25H15.5V4.75H9V7H3.5V4.75H1.5V3.25H3.5V1H9V3.25ZM5 2.5H7.5V5.5H5V2.5Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11.25V9H13.5V11.25H15.5V12.75H13.5V15H8V12.75H1.5V11.25H8ZM9.5 10.5H12V13.5H9.5V10.5Z"
        fill="#2A3038"
      />
    </svg>
  ),
  bee_yellow: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.05337 15C9.29343 17.2851 10.3347 19.4189 12 21.0164C13.6653 19.4189 14.7066 17.2851 14.9466 15H9.05337ZM9 7.5H15V5.25C15 3.59315 13.6569 2.25 12 2.25C10.3431 2.25 9 3.59315 9 5.25V7.5Z"
        fill="#F1B729"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5.25V6.0375L1.25616 11.806C0.449778 12.6527 0 13.7926 0 14.9619C0 17.4471 2.01472 19.5 4.5 19.5C5.67958 19.5 6.75315 19.0461 7.55562 18.3035C8.26268 20.1344 9.41618 21.7853 10.9405 23.0919L12 24L13.0595 23.0919C14.5838 21.7853 15.7373 20.1344 16.4444 18.3035C17.2468 19.0461 18.3204 19.5 19.5 19.5C21.9853 19.5 24 17.4471 24 14.9619C24 13.7926 23.5502 12.6527 22.7438 11.806L17.25 6.0375V5.25C17.25 2.35051 14.8995 0 12 0C9.1005 0 6.75 2.35051 6.75 5.25ZM9.05337 15C9.29343 17.2851 10.3347 19.4189 12 21.0164C13.6653 19.4189 14.7066 17.2851 14.9466 15H9.05337ZM15 12.75V9.75H9V12.75H15ZM9 7.5H15V5.25C15 3.59315 13.6569 2.25 12 2.25C10.3431 2.25 9 3.59315 9 5.25V7.5ZM2.88547 13.3578L6.75 9.3V15C6.75 16.2426 5.74264 17.25 4.5 17.25C3.28198 17.25 2.25 16.2293 2.25 14.9619C2.25 14.3623 2.48305 13.7803 2.88547 13.3578ZM17.25 15V9.3L21.1145 13.3578C21.517 13.7803 21.75 14.3623 21.75 14.9619C21.75 16.2293 20.718 17.25 19.5 17.25C18.2574 17.25 17.25 16.2426 17.25 15Z"
        fill="#2A3038"
      />
    </svg>
  ),
  search: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0625 7.875C14.0625 11.2923 11.2923 14.0625 7.875 14.0625C4.45774 14.0625 1.6875 11.2923 1.6875 7.875C1.6875 4.45774 4.45774 1.6875 7.875 1.6875C11.2923 1.6875 14.0625 4.45774 14.0625 7.875ZM6.28401 9.46599L5.09077 10.6592C3.55308 9.12154 3.55308 6.62846 5.09077 5.09077C6.62846 3.55308 9.12155 3.55308 10.6592 5.09077L9.46599 6.28401C8.58731 5.40533 7.16269 5.40533 6.28401 6.28401C5.40533 7.16269 5.40533 8.58731 6.28401 9.46599Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        d="M5.09077 10.6592L6.28401 9.46599C5.40533 8.58731 5.40533 7.16269 6.28401 6.28401C7.16269 5.40533 8.58731 5.40533 9.46599 6.28401L10.6592 5.09077C9.12155 3.55308 6.62846 3.55308 5.09077 5.09077C3.55308 6.62846 3.55308 9.12154 5.09077 10.6592Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 15.75C9.74568 15.75 11.464 15.0977 12.815 14.0082L16.7846 17.9779L17.9779 16.7846L14.0082 12.815C15.0977 11.464 15.75 9.74568 15.75 7.875C15.75 3.52576 12.2242 0 7.875 0C3.52576 0 0 3.52576 0 7.875C0 12.2242 3.52576 15.75 7.875 15.75ZM7.875 14.0625C11.2923 14.0625 14.0625 11.2923 14.0625 7.875C14.0625 4.45774 11.2923 1.6875 7.875 1.6875C4.45774 1.6875 1.6875 4.45774 1.6875 7.875C1.6875 11.2923 4.45774 14.0625 7.875 14.0625Z"
        fill="#2A3038"
      />
    </svg>
  ),
  gift: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 2.25V5.25H10.875V4.5C10.875 3.25736 9.86764 2.25 8.625 2.25H6.75ZM17.25 2.25V5.25H13.125V4.5C13.125 3.25736 14.1324 2.25 15.375 2.25H17.25ZM20.25 12.75V20.25H13.125V12.75H20.25ZM10.875 12.75V20.25H3.75V12.75H10.875Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.625 0C9.96903 0 11.1754 0.589225 12 1.52345C12.8246 0.589225 14.031 0 15.375 0H19.5V5.25H24V12.75H22.5V22.5H1.5V12.75H0V5.25H4.5V0H8.625ZM6.75 2.25V5.25H10.875V4.5C10.875 3.25736 9.86764 2.25 8.625 2.25H6.75ZM21.75 7.5V10.5H2.25V7.5H21.75ZM17.25 2.25V5.25H13.125V4.5C13.125 3.25736 14.1324 2.25 15.375 2.25H17.25ZM20.25 12.75V20.25H13.125V12.75H20.25ZM10.875 12.75V20.25H3.75V12.75H10.875Z"
        fill="#2A3038"
      />
    </svg>
  ),
  trophy: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3H10V15C10 18.3137 12.6863 21 16 21C19.3137 21 22 18.3137 22 15V3Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 4V0H7V4H0V8C0 12.6232 3.1373 16.5136 7.39907 17.6584C8.38973 20.8672 11.1267 23.3096 14.5 23.8756V29H8V32H24V29H17.5V23.8756C20.8733 23.3096 23.6103 20.8672 24.6009 17.6584C28.8627 16.5136 32 12.6232 32 8V4H25ZM22 3H10V15C10 18.3137 12.6863 21 16 21C19.3137 21 22 18.3137 22 15V3ZM25 7V14.3264C27.3649 13.2029 29 10.7924 29 8V7H25ZM3 7H7V14.3264C4.63505 13.2029 3 10.7924 3 8V7Z"
        fill="#2A3038"
      />
    </svg>
  ),
  save: (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84375 2.34375H10.23L13.1562 5.26999V12.6562H11.2812L11.2812 8.4375L4.71875 8.4375L4.71875 12.6562H2.84375V2.34375Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.4375 14.0625V0.9375H10.8125L14.5625 4.6875V14.0625H1.4375ZM2.84375 2.34375H10.23L13.1562 5.26999V12.6562H11.2812L11.2812 8.4375L4.71875 8.4375L4.71875 12.6562H2.84375V2.34375ZM9.875 12.6562H6.125L6.125 9.84375H9.875V12.6562Z"
        fill="#2A3038"
      />
    </svg>
  ),
  fire: (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1562 8.4375C13.1562 10.3669 12.0966 12.0487 10.5275 12.9328C10.5607 12.7677 10.5781 12.5968 10.5781 12.4219C10.5781 11.0563 10.0314 9.97659 9.50614 9.25441C9.24255 8.89196 8.97901 8.6118 8.77946 8.42057C8.67939 8.32467 8.59453 8.25029 8.53259 8.1983C8.36175 8.05493 8.17817 7.9266 8 7.79297C7.82183 7.9266 7.63825 8.05493 7.46741 8.1983C7.40547 8.25029 7.32061 8.32467 7.22054 8.42057C7.02099 8.6118 6.75745 8.89196 6.49386 9.25441C5.96863 9.97659 5.42188 11.0563 5.42188 12.4219C5.42188 12.5968 5.4393 12.7677 5.47251 12.9328C3.90342 12.0487 2.84375 10.3669 2.84375 8.4375C2.84375 7.19727 3.3798 6.07364 3.99261 5.20549C4.25121 4.83914 4.51056 4.53691 4.72061 4.31304C4.7701 5.56375 5.79966 6.5625 7.0625 6.5625C8.35692 6.5625 9.40625 5.51317 9.40625 4.21875V1.40625H9.4428C9.52482 1.46621 9.62126 1.53929 9.72894 1.62544C10.1108 1.93088 10.6249 2.39336 11.1384 3.00963C12.1642 4.24054 13.1562 6.04231 13.1562 8.4375Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.6244 15 14.5625 12.0619 14.5625 8.4375C14.5625 2.8125 9.875 0 9.875 0H8V4.21875C8 4.73652 7.58027 5.15625 7.0625 5.15625C6.54473 5.15625 6.125 4.73652 6.125 4.21875V2.8125H4.25C4.25 2.8125 1.4375 5.15625 1.4375 8.4375C1.4375 12.0619 4.37563 15 8 15ZM8 13.5938C8.64721 13.5938 9.17188 13.0691 9.17188 12.4219C9.17188 11.4437 8.78113 10.6484 8.36886 10.0815C8.2417 9.90669 8.11452 9.75651 8 9.6329C7.88548 9.75651 7.7583 9.90669 7.63114 10.0815C7.21887 10.6484 6.82812 11.4437 6.82812 12.4219C6.82812 13.0691 7.35279 13.5938 8 13.5938ZM13.1562 8.4375C13.1562 10.3669 12.0966 12.0487 10.5275 12.9328C10.5607 12.7677 10.5781 12.5968 10.5781 12.4219C10.5781 11.0563 10.0314 9.97659 9.50614 9.25441C9.24255 8.89196 8.97901 8.6118 8.77946 8.42057C8.67939 8.32467 8.59453 8.25029 8.53259 8.1983C8.40878 8.0944 8.27829 7.9984 8.148 7.90255C8.09851 7.86614 8.04905 7.82976 8 7.79297C7.95095 7.82976 7.90149 7.86614 7.852 7.90255C7.72171 7.9984 7.59122 8.0944 7.46741 8.1983C7.40547 8.25029 7.32061 8.32467 7.22054 8.42057C7.02099 8.6118 6.75745 8.89196 6.49386 9.25441C5.96863 9.97659 5.42188 11.0563 5.42188 12.4219C5.42188 12.5968 5.4393 12.7677 5.47251 12.9328C3.90342 12.0487 2.84375 10.3669 2.84375 8.4375C2.84375 7.19727 3.3798 6.07364 3.99261 5.20549C4.25121 4.83914 4.51056 4.53691 4.72061 4.31304C4.7701 5.56375 5.79966 6.5625 7.0625 6.5625C8.35692 6.5625 9.40625 5.51317 9.40625 4.21875V1.40625H9.44279C9.52482 1.46621 9.62126 1.53929 9.72894 1.62544C10.1108 1.93088 10.6249 2.39336 11.1384 3.00963C12.1642 4.24054 13.1562 6.04231 13.1562 8.4375Z"
        fill="#2A3038"
      />
    </svg>
  ),
  list: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.5V7.25H12.5V3.5H3.5ZM12.5 12.5H3.5V8.75H12.5V12.5Z"
        fill="black"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0V16H3.5V14H12.5V16H14V0H12.5V2H3.5V0H2ZM3.5 3.5V7.25H12.5V3.5H3.5ZM12.5 12.5H3.5V8.75H12.5V12.5Z"
        fill="#2A3038"
      />
    </svg>
  ),
  columns: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 13.5L12.5 6L10.5 6L10.5 13.5L12.5 13.5Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 6L5.5 9.5L3.5 9.5L3.5 6L5.5 6Z"
        fill="#2A3038"
        fillOpacity="0.15"
      />
      <path d="M1 2.5L15 2.5V1L1 1L1 2.5Z" fill="#2A3038" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.5L9 4.5L9 15L14 15L14 4.5ZM12.5 13.5L12.5 6H10.5L10.5 13.5L12.5 13.5Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.5L7 11H2L2 4.5L7 4.5ZM5.5 6L5.5 9.5H3.5V6H5.5Z"
        fill="#2A3038"
      />
    </svg>
  ),
  new_orders: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 18.75C6.75 19.5784 6.07843 20.25 5.25 20.25C4.42157 20.25 3.75 19.5784 3.75 18.75C3.75 17.9216 4.42157 17.25 5.25 17.25C6.07843 17.25 6.75 17.9216 6.75 18.75Z"
        fill="#F1B729"
      />
      <path
        d="M1.5 1.5H5.25C14.7769 1.5 22.5 9.22309 22.5 18.75V22.5H20.25V18.75C20.25 10.4657 13.5343 3.75 5.25 3.75H1.5V1.5Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 22.5C7.32107 22.5 9 20.8211 9 18.75C9 16.6789 7.32107 15 5.25 15C3.17893 15 1.5 16.6789 1.5 18.75C1.5 20.8211 3.17893 22.5 5.25 22.5ZM5.25 20.25C6.07843 20.25 6.75 19.5784 6.75 18.75C6.75 17.9216 6.07843 17.25 5.25 17.25C4.42157 17.25 3.75 17.9216 3.75 18.75C3.75 19.5784 4.42157 20.25 5.25 20.25Z"
        fill="#2A3038"
      />
      <path
        d="M5.25 8.25H1.5V10.5H5.25C9.80635 10.5 13.5 14.1937 13.5 18.75V22.5H15.75V18.75C15.75 12.951 11.049 8.25 5.25 8.25Z"
        fill="#2A3038"
      />
    </svg>
  ),
  rule: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.0718 4.57735L8 1.1547L13.9282 4.57735V11.4227L8 14.8453L2.0718 11.4227V4.57735Z"
        stroke="#2A3038"
        strokeWidth="2"
      />
      <path
        d="M7.26795 7.57735L8 7.1547L8.73205 7.57735V8.42265L8 8.8453L7.26795 8.42265V7.57735Z"
        stroke="#2A3038"
        strokeWidth="2"
      />
    </svg>
  ),
  hexagon: (
    <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0.57735C14.6188 0.220085 15.3812 0.220085 16 0.57735L28.7224 7.92265C29.3412 8.27992 29.7224 8.94017 29.7224 9.6547V24.3453C29.7224 25.0598 29.3412 25.7201 28.7224 26.0774L16 33.4226C15.3812 33.7799 14.6188 33.7799 14 33.4226L1.27757 26.0774C0.658766 25.7201 0.277568 25.0598 0.277568 24.3453V9.6547C0.277568 8.94017 0.658766 8.27991 1.27757 7.92265L14 0.57735Z"
        fill="#BFC6CF"
      />
    </svg>
  ),
  user: (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C9 4.65685 7.65685 6 6 6C4.34315 6 3 4.65685 3 3C3 1.34315 4.34315 0 6 0C7.65685 0 9 1.34315 9 3ZM6 4.5C6.82843 4.5 7.5 3.82843 7.5 3C7.5 2.17157 6.82843 1.5 6 1.5C5.17157 1.5 4.5 2.17157 4.5 3C4.5 3.82843 5.17157 4.5 6 4.5Z"
        fill="#2A3038"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8C10.6569 8 12 9.34315 12 11V14H0V11C0 9.34315 1.34315 8 3 8H9ZM10.5 11C10.5 10.1716 9.82843 9.5 9 9.5H3C2.17157 9.5 1.5 10.1716 1.5 11V12.5H10.5V11Z"
        fill="#2A3038"
      />
    </svg>
  ),
  drag_handle: (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.2429 4.48579C3.48161 4.48579 4.48579 3.48161 4.48579 2.2429C4.48579 1.00418 3.48161 0 2.2429 0C1.00418 0 0 1.00418 0 2.2429C0 3.48161 1.00418 4.48579 2.2429 4.48579Z"
        fill="#CCD1D9"
      />
      <path
        d="M8.94309 4.48579C10.1818 4.48579 11.186 3.48161 11.186 2.2429C11.186 1.00418 10.1818 0 8.94309 0C7.70437 0 6.7002 1.00418 6.7002 2.2429C6.7002 3.48161 7.70437 4.48579 8.94309 4.48579Z"
        fill="#CCD1D9"
      />
      <path
        d="M2.2429 11.2428C3.48161 11.2428 4.48579 10.2386 4.48579 8.99988C4.48579 7.76117 3.48161 6.75699 2.2429 6.75699C1.00418 6.75699 0 7.76117 0 8.99988C0 10.2386 1.00418 11.2428 2.2429 11.2428Z"
        fill="#CCD1D9"
      />
      <path
        d="M8.94309 11.2428C10.1818 11.2428 11.186 10.2386 11.186 8.99988C11.186 7.76117 10.1818 6.75699 8.94309 6.75699C7.70437 6.75699 6.7002 7.76117 6.7002 8.99988C6.7002 10.2386 7.70437 11.2428 8.94309 11.2428Z"
        fill="#CCD1D9"
      />
      <path
        d="M2.2429 18C3.48161 18 4.48579 16.9959 4.48579 15.7571C4.48579 14.5184 3.48161 13.5143 2.2429 13.5143C1.00418 13.5143 0 14.5184 0 15.7571C0 16.9959 1.00418 18 2.2429 18Z"
        fill="#CCD1D9"
      />
      <path
        d="M8.94309 18C10.1818 18 11.186 16.9959 11.186 15.7571C11.186 14.5184 10.1818 13.5143 8.94309 13.5143C7.70437 13.5143 6.7002 14.5184 6.7002 15.7571C6.7002 16.9959 7.70437 18 8.94309 18Z"
        fill="#CCD1D9"
      />
    </svg>
  ),
  arrow_vertical: (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.8754 1.43766C7.6709 1.42771 7.5384 1.41545 7.40513 1.41545C6.0334 1.41545 4.66167 1.42005 3.28918 1.41545C2.65041 1.41162 2.27971 0.864003 2.60446 0.374592C2.72394 0.194605 3.00732 0.0222767 3.21718 0.019979C5.35098 -0.00606168 7.48555 6.21728e-05 9.61936 0.00695529C10.0689 0.00695529 10.3454 0.301829 10.3462 0.759838C10.3498 2.86913 10.3498 4.97792 10.3462 7.08619C10.3462 7.55263 10.0506 7.86205 9.63621 7.86129C9.23564 7.86129 8.93847 7.54037 8.93694 7.07317C8.93004 5.70144 8.93694 4.32894 8.93694 2.95721V2.44329C8.75465 2.61638 8.64743 2.71366 8.54556 2.81552C6.28768 5.06727 4.03031 7.32056 1.77345 9.57537C1.2917 10.0571 0.89956 10.1261 0.561797 9.79212C0.224034 9.45819 0.286839 9.05226 0.759401 8.5797C3.0229 6.3157 5.28614 4.05194 7.54912 1.78844C7.64716 1.69347 7.73371 1.59161 7.8754 1.43766Z"
        fill="#F1B729"
      />
    </svg>
  ),
  x: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.9394 6L0.469727 1.53033L1.53039 0.469666L6.00006 4.93934L10.4697 0.469666L11.5304 1.53033L7.06072 6L11.5304 10.4697L10.4697 11.5303L6.00006 7.06066L1.53039 11.5303L0.469727 10.4697L4.9394 6Z"
        fill="#2A3038"
      />
    </svg>
  ),
  copy: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H10V4H4V10H0V0Z" fill="#E0E0E0" />
      <path d="M16 6H6V16H16V6Z" fill="#E0E0E0" />
    </svg>
  ),
  arr_right: (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.75L0 6.75L6.55671e-08 5.25L8 5.25L8 0L10 8.74225e-08L16 6L10 12H8L8 6.75ZM13.8787 6L9.5 1.62132L9.5 10.3787L13.8787 6Z"
        fill="black"
      />
    </svg>
  ),
  wifi: (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 2.14124C10.1633 2.14124 12.2381 3.00062 13.7678 4.53033L14.9393 5.7019L16 4.64124L14.8284 3.46967C14.6021 3.24329 14.3656 3.02931 14.1201 2.82817C12.4017 1.4202 10.241 0.641239 8 0.641239C5.43884 0.641239 2.98258 1.65865 1.17157 3.46967L0 4.64124L1.06066 5.7019L2.23223 4.53033C3.76194 3.00062 5.83667 2.14124 8 2.14124Z"
        fill="black"
      />
      <path
        d="M3.88909 8.53033L2.82843 7.46967L4.00001 6.29809C5.06087 5.23723 6.49971 4.64124 8.00001 4.64124C9.5003 4.64124 10.9391 5.23723 12 6.29809L13.1716 7.46966L12.1109 8.53032L10.9393 7.35876C10.1598 6.57919 9.10247 6.14124 8.00001 6.14124C6.89754 6.14124 5.84023 6.57919 5.06067 7.35875L3.88909 8.53033Z"
        fill="black"
      />
      <path
        d="M6.71752 11.3588L5.65686 10.2981L6.82844 9.12652C7.13916 8.8158 7.56059 8.64124 8.00001 8.64124C8.43944 8.64124 8.86086 8.8158 9.17159 9.12652L10.3432 10.2981L9.28249 11.3587L8.11093 10.1872C8.08151 10.1578 8.04161 10.1412 8.00001 10.1412C7.95841 10.1412 7.91851 10.1578 7.8891 10.1872L6.71752 11.3588Z"
        fill="black"
      />
    </svg>
  ),
  arrow_back: (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 13H4L0 9L4 5H5V8H11C12.6569 8 14 6.65685 14 5C14 3.34315 12.6569 2 11 2H4V0H11C13.7614 0 16 2.23858 16 5C16 7.76142 13.7614 10 11 10H5V13Z"
        fill="#F1B729"
      />
    </svg>
  ),
  icons_menu_point: (
    <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75843 5.75868C4.28201 5.75868 5.51711 4.52358 5.51711 3C5.51711 1.47643 4.28201 0.241325 2.75843 0.241325C1.23486 0.241325 -0.000244141 1.47643 -0.000244141 3C-0.000244141 4.52358 1.23486 5.75868 2.75843 5.75868Z"
        fill="#BFC6CF"
      />
      <path
        d="M10.9994 5.75868C12.523 5.75868 13.7581 4.52358 13.7581 3C13.7581 1.47643 12.523 0.241325 10.9994 0.241325C9.47583 0.241325 8.24072 1.47643 8.24072 3C8.24072 4.52358 9.47583 5.75868 10.9994 5.75868Z"
        fill="#BFC6CF"
      />
      <path
        d="M19.2413 5.75868C20.7649 5.75868 22 4.52358 22 3C22 1.47643 20.7649 0.241325 19.2413 0.241325C17.7178 0.241325 16.4827 1.47643 16.4827 3C16.4827 4.52358 17.7178 5.75868 19.2413 5.75868Z"
        fill="#BFC6CF"
      />
    </svg>
  ),
  copy_icon: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 0H10.5V4H4.5V10H0.5V0Z" fill="#E0E0E0" />
      <path d="M16.5 6H6.5V16H16.5V6Z" fill="#E0E0E0" />
    </svg>
  ),
  three_dots: (
    <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75868 5.75869C4.28225 5.75869 5.51735 4.52358 5.51735 3.00001C5.51735 1.47643 4.28225 0.241333 2.75868 0.241333C1.2351 0.241333 0 1.47643 0 3.00001C0 4.52358 1.2351 5.75869 2.75868 5.75869Z"
        fill="#BFC6CF"
      />
      <path
        d="M10.9999 5.75869C12.5235 5.75869 13.7586 4.52358 13.7586 3.00001C13.7586 1.47643 12.5235 0.241333 10.9999 0.241333C9.47631 0.241333 8.24121 1.47643 8.24121 3.00001C8.24121 4.52358 9.47631 5.75869 10.9999 5.75869Z"
        fill="#BFC6CF"
      />
      <path
        d="M19.2421 5.75869C20.7657 5.75869 22.0008 4.52358 22.0008 3.00001C22.0008 1.47643 20.7657 0.241333 19.2421 0.241333C17.7185 0.241333 16.4834 1.47643 16.4834 3.00001C16.4834 4.52358 17.7185 5.75869 19.2421 5.75869Z"
        fill="#BFC6CF"
      />
    </svg>
  ),
  plus: (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1.42529H6V6.42529L1 6.42529V10.4253H6V15.4253H10V10.4253H15V6.42529L10 6.42529V1.42529Z"
        fill="#F1B729"
      />
    </svg>
  ),
  icons_add_time: (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18ZM8.375 7.875V4.5H10.625V7.875H14V10.125H10.625V13.5H8.375V10.125H5V7.875H8.375Z"
        fill="#E0E0E0"
      />
    </svg>
  ),
};

interface IProps {
  slug?: string;
  style?: React.CSSProperties;
  className?: string;
}

const Icon = ({ slug = '', className }: IProps) => {
  return slug in customIcons ? (
    customIcons[slug]
  ) : (
    <i style={{ lineHeight: 0 }} className={$class(`ui-icon fi ${slug}`, className)}></i>
  );
};

export default Icon;
