import { config } from './config';

declare global {
  interface Window {
    uWS: WebSocket;
  }
}

class uWS {
  private events = new Map();
  private reconnectInterval: null | NodeJS.Timeout = null;
  private version = 0; // не менять

  // private url = 'localhost:30001/ws';
  private url = config.UWA_HOST;

  constructor() {
    if (window.uWS) return;
    this.connect();
  }

  private connect() {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('token undefined');
      return;
    }
    window.uWS = new WebSocket(`wss://${this.url}?token=${token}`);

    window.uWS.onopen = () => {
      console.log('WebSocket Connected');
      if (this.reconnectInterval) {
        clearInterval(this.reconnectInterval);
        this.reconnectInterval = null;
      }
    };

    window.uWS.onmessage = (event) => {
      const message = event.data;
      try {
        const json = JSON.parse(message);
        if (!json.e) return;

        // Уведомление об ошибке
        if (json.e == 'error') {
          if (json.d.error && json.d.error.msg) console.error('WebSocket', json.d.error.msg);
          return;
        }

        // Уведомление об успешном соединении
        if (json.e == 'open') {
          // Подписка на events
          this.events.forEach((eventData, e) => {
            window.uWS.send(
              JSON.stringify({
                c: 'sub',
                e,
                u: eventData.u,
              })
            );
          });
        }

        // Проверка версии websocket
        if (json.v && this.version !== json.v) {
          if (this.version) this.versionChange({ version: this.version, versionNew: json.v });
          this.version = json.v;
        }

        const eventData = this.events.get(json.e);
        if (!eventData || !eventData.cb) return;

        eventData.cb(json.d);
        eventData.u = new Date().getTime();
        this.events.set(json.e, eventData);
      } catch (e) {
        console.error(e);
      }
    };

    window.uWS.onclose = () => {
      console.log('WebSocket Disconnected');
      this.reconnect();
    };

    window.uWS.onerror = (e) => {
      console.error('WebSocket Disconnected', e);
    };
  }

  private reconnect() {
    if (!this.reconnectInterval) {
      this.reconnectInterval = setInterval(() => {
        this.connect();
      }, 5000); // Попытка подключения каждые 5 секунд
    }
  }

  // Проверка на подключение
  isReady() {
    return window.uWS?.readyState === WebSocket.OPEN;
  }

  /**
   * Подписка на событие
   * @param e
   * @param cb
   * @returns boolean
   */
  subscribe(e: string, cb: any) {
    if (!e || e === '') {
      console.error('event invalid', e);
      return false;
    }
    if (this.events.has(e)) {
      console.error('Попытка повторной подписки на событие', e);
      return false;
    }
    const u = new Date().getTime();
    this.events.set(e, { u, cb });
    if (this.isReady()) {
      window.uWS.send(JSON.stringify({ c: 'sub', e, u }));
      console.log('Websocket subscribe', { e, cb });
      return true;
    }
    return false;
  }

  /**
   * Отписка от события
   * @param e
   */
  unSubscribe(e: string) {
    this.events.delete(e);
    if (this.isReady()) {
      window.uWS.send(JSON.stringify({ c: 'unSub', e }));
      console.log('Websocket unSubscribe', { e });
    }
  }

  // Для теста
  send(d: any) {
    window.uWS.send(JSON.stringify({ c: 'send', d }));
  }

  // Сменилась версия
  versionChange({ version, versionNew }: { version: number; versionNew: number }) {
    console.log(`Сменилась версия c ${version} на ${versionNew}`);
  }
}

export default new uWS();
