import styles from './index.module.scss';
import { FC, ReactNode } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { StagesDataType } from '../../ types';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';

interface IProps extends StagesDataType {
  children: ReactNode;
}

const JobColumn: FC<IProps> = ({ children, _id, name }) => {
  const { setNodeRef } = useDroppable({
    id: _id,
  });

  return (
    <div ref={setNodeRef} className={styles.column}>
      <p className={styles.title}>
        {name} <b>({StagesStore.dataJobsList?.filter((job) => job.stageId === _id).length})</b>
      </p>
      <div className={styles.column_scroll}>{children}</div>
    </div>
  );
};

export default JobColumn;
