import { Form, Select, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AuthStore } from '../../../DataSource/Stores/AuthStore';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import Lang from '../../Lang';
import Button from '../../ui/Button';
import styles from './index.module.scss';

export type FieldTypetwo = {
  status: any;
};
const StatusProf: React.FC = () => {
  const iduser: any = AuthStore.currentUserData.user._id;
  const [defStatus, setDefStatus] = useState<string>('');

  const [selectedStatus, setSelectedStatus] = useState('');
  const workid = '66e170b52fc0a03d58789c24';

  useEffect(() => {
    const fetchDefStatus = async () => {
      try {
        await StagesStore.getUserStatus(workid);
        const userStatus: any = StagesStore.dataStatus[iduser];
        const userStatus2: string = userStatus;
        setDefStatus(
          userStatus2 === 'inactive' ? 'Оффлайн' : userStatus2 === 'work' ? 'В работе' : 'Онлайн'
        );
      } catch (e) {
        console.error(e);
      }
    };

    if (workid) {
      fetchDefStatus();
    }
  }, [workid, AuthStore.currentUserData.user._id]);

  const [form] = Form.useForm();

  const onFinish = async () => {
    await StagesStore.changeUserStatus(workid, selectedStatus);
    if (StagesStore.loadingChangeStatusError) {
      message.error('Update Error');
    } else {
      message.info('Success');
    }
  };

  const handleChange = (value: string) => {
    setSelectedStatus(value);
  };

  return (
    <div className={styles.status_wrp}>
      <Form
        key={Date.now()}
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item<FieldTypetwo> label={''} name="status">
          <Select
            defaultValue={defStatus}
            onChange={handleChange}
            options={[
              { value: 'inactive', label: 'Оффлайн' },
              { value: 'work', label: 'Нет на месте' },
              { value: 'active', label: 'Онлайн' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button icon={'bee'} className={styles.button_prime} variant="primary">
            {Lang({ str: 'save', section: 'default' })}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(StatusProf);
