import './index.scss';
import { $class } from '../../../utils';
import React, { FC } from 'react';

type Status = 'in_progress' | 'completed';

interface IProps {
  status: Status;
}

const ProgressBar: FC<IProps> = ({ status }) => {
  return (
    <div className="progress-bar-wrap">
      <p>
        {status === 'in_progress' && 'ост. 25 мин'}
        {status === 'completed' && 'за 38 мин'}
      </p>
      <div
        className={$class(
          'progress-bar',
          ['in-progress', status === 'in_progress'],
          ['completed', status === 'completed']
        )}
      >
        <div style={status === 'in_progress' ? { width: '25%' } : {}}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
