// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k5rxhHqufc0wTH_iRBgY{background-color:rgba(255,255,255,.6980392157);border-radius:8px;box-shadow:0 2px 4px 0 rgba(0,0,0,.1490196078);display:flex;align-items:end;justify-content:space-between;grid-column-start:2;padding:18px 24px}.k5rxhHqufc0wTH_iRBgY .AQj0qjGusBKgpXIQViBm{width:112px}.k5rxhHqufc0wTH_iRBgY .v9rUcx8udWH5HAE_b5Pv{border-bottom:1px solid #e0e0e0;color:#2a3038;font-size:18px;display:flex;align-items:center;gap:16px;padding-bottom:4px}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACE,8CAAA,CACA,iBAAA,CACA,8CAAA,CACA,YAAA,CACA,eAAA,CACA,6BAAA,CACA,mBAAA,CACA,iBAAA,CAEA,4CACE,WAAA,CAGF,4CACE,+BAAA,CACA,aAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".header {\n  background-color: #FFFFFFB2;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px 0 #00000026;\n  display: flex;\n  align-items: end;\n  justify-content: space-between;\n  grid-column-start: 2;\n  padding: 18px 24px;\n\n  .logo {\n    width: 112px;\n  }\n\n  .dropdownBtn {\n    border-bottom: 1px solid #E0E0E0;\n    color: #2A3038;\n    font-size: 18px;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    padding-bottom: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `k5rxhHqufc0wTH_iRBgY`,
	"logo": `AQj0qjGusBKgpXIQViBm`,
	"dropdownBtn": `v9rUcx8udWH5HAE_b5Pv`
};
export default ___CSS_LOADER_EXPORT___;
