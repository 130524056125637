import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import {
  DataType,
  JobsDataType,
  StagesDataType,
  // StatusType,
  Task,
} from '../../../components/ types';
import { basicRequestGet } from '../../Requests/basicRequestGet';
import { basicRequestPatch } from '../../Requests/basicRequestPatch';
// import workspaces from '../../../components/Cabinet/Workspaces';

export class StagesStoreActionsClass {
  @observable isModalOpen: boolean = false;

  @observable loading: boolean = false;
  @observable loadingError: boolean = false;
  @observable getloading: boolean = false;
  @observable getError: boolean = false;

  @observable loadingJobs: boolean = false;
  @observable loadingJobsError: boolean = false;

  @observable loadingJob: boolean = false;
  @observable loadingJobError: boolean = false;

  @observable loadingArchives: boolean = false;
  @observable loadingArchivesEr: boolean = false;

  @observable dataList: StagesDataType[] = [];
  @observable dataUserTask: Task[] = [];
  @observable dataSystemTask: Task[] = [];

  @observable dataJobsList: JobsDataType[] = [];
  @observable dataArchives: JobsDataType[] = [];

  @observable dataJobs: DataType = {};

  @observable jobUpdateLoading: boolean = false;
  @observable jobUpdateError: boolean = false;

  @observable taskUpdateLoading: boolean = false;
  @observable taskUpdateError: boolean = false;

  @observable dataUpdateJob: StagesDataType | null = null;

  @observable dataMyTasks: Task[] = [];
  @observable dataMyTaskOpened: Task | null = null;
  @observable loadingMyTasks: boolean = false;
  @observable loadingMyTaskOpen: boolean = false;
  @observable loadingErrorMyTasks: boolean = false;
  @observable loadingErrorMyTaskOpen: boolean = false;

  @observable layoutPage: string = 'library';
  @observable layoutPageJob: string = 'joblay';
  @observable dataQueueList: JobsDataType[] = [];
  @observable loadingQueue: boolean = false;
  @observable loadingQueueError: boolean = false;

  @observable dataStatus: Record<string, string> = {};

  @observable loadingChangeStatusError: boolean = false;
  @observable loadingChangeStatus: boolean = false;

  @observable tabsIndex: number = 0;

  @observable selectedJob: JobsDataType | null = null;
  @observable isJobSelected: boolean = false;
  @observable showText: boolean = false;

  @observable selectedTask: Task | null = null;
  @observable isSelectedTask: boolean = false;

  @observable showTextTask: boolean = false;

  @observable taskUpdateDateError: boolean = false;
  @observable taskUpdateDateLoading: boolean = false;

  @observable dataUpdateTaskDate: Task | null = null;

  constructor() {
    // makeObservable(this);
    makeAutoObservable(this);
  }

  @action setSelectedTask(task: Task | null) {
    this.selectedTask = task;
  }

  @action setIsSelectedTask(value: boolean) {
    this.isSelectedTask = value;
  }

  @action setSelectedJob(job: JobsDataType | null) {
    this.selectedJob = job;
    this.isJobSelected = job !== null;
  }

  @action setIsSelectedJob(value: boolean) {
    this.isJobSelected = value;
  }
  @action setShowText(value: boolean) {
    this.showText = value;
  }

  @action setShowTextTask(value: boolean) {
    this.showTextTask = value;
  }

  //obnovlenye date
  @action
  async updateTaskexpectedDate(workspaceId: string, id: string, expectedDate: string) {
    console.log('update Task expectedDate');
    this.taskUpdateDateLoading = true;
    this.taskUpdateDateError = false;
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages-steps-tasks/${id}/expectedDate`,
        {
          expectedDate,
        }
      );
      this.dataUpdateTaskDate = res.data;
    } catch (error) {
      console.error('update Task expectedDate|error=', error);
      this.taskUpdateDateError = true;
    } finally {
      this.taskUpdateDateLoading = false;
    }
  }
  //get stages all list//

  @action
  async getList(workspaceId: string) {
    console.log('getList stage');
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.loadingError = false;
    this.dataList = [];
    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/stages', {
        workspaceId,
      });
      console.log('---- stages|getList|res.data=', res.data);
      this.dataList = res.data.data as StagesDataType[];
    } catch (error) {
      console.error('getList stages|error=', error);
      this.loadingError = true;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  //get jobs all list//
  @action
  async getListJobs(workspaceId: string) {
    console.log('getList jobs spisok');
    if (this.loadingJobs) {
      return;
    }
    this.loadingJobs = true;
    this.loadingJobsError = false;

    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/jobs', {
        // workspaceId,
      });
      console.log('----getList jobs spisok|res.data=', res.data);
      runInAction(() => {
        this.dataJobsList = res.data.data as JobsDataType[];
      });
    } catch (error) {
      console.error('getJobs|error=', error);
      runInAction(() => {
        this.loadingJobsError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingJobs = false;
      });
    }
  }

  //get jobs archivs list//
  @action
  async getArchivsJobs(workspaceId: string, start: number, end: number) {
    console.log('getList archivs');
    if (this.loadingArchives) {
      return;
    }
    this.loadingArchives = true;
    this.loadingArchivesEr = false;
    this.dataArchives = [];
    try {
      const res = await basicRequestGet(
        '/workspaces/' + workspaceId + '/jobs/archived?start=' + start + '&end=' + end,
        { start, end }
      );
      console.log('---- ww|getArchivs|res.data=', res.data.data);
      runInAction(() => {
        this.dataArchives = res.data.data as JobsDataType[];
      });
    } catch (error) {
      console.error('getJobs|error=', error);
      runInAction(() => {
        this.loadingArchivesEr = true;
      });
    } finally {
      runInAction(() => {
        this.loadingArchives = false;
      });
    }
  }

  //get one jobs by id//
  @action
  async getJobsId(workspaceId: string, jobId: string) {
    console.log('get one jobs');
    console.log(workspaceId);
    console.log(jobId);
    if (this.loadingJob) {
      return;
    }
    this.loadingJob = true;
    this.loadingJobError = false;
    this.dataJobs = {};
    this.dataUserTask = [];
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/jobs/${jobId}`, {
        jobId,
      });
      console.log('---- ww|getJobsID|res.data=', res.data);
      runInAction(() => {
        this.dataJobs = res.data as DataType;
        this.dataUserTask = res.data.userTasks;
        this.dataSystemTask = res.data.systemTasks;
      });
    } catch (error) {
      console.error('getJobsID|error=', error);
      this.loadingJobError = true;
    } finally {
      runInAction(() => {
        this.loadingJob = false;
      });
    }
  }

  @action
  async updateJobArchived(workspaceId: string, jobId: string, isArchived: boolean) {
    console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/archived`, {
        isArchived,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobStage(workspaceId: string, jobId: string, stageId: string) {
    console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/stage`, {
        stageId,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobAssignedTo(jobId: string, workspaceId: string, assignedTo: string) {
    console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/assignedTo`, {
        workspaceId,
        assignedTo,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobTargetDate(jobId: string, workspaceId: string, targetDate: string) {
    console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(`/workspaces/${workspaceId}/jobs/${jobId}/targetDate`, {
        workspaceId,
        targetDate,
      });
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateJobImage(workspaceId: string, jobId: string, formData: FormData) {
    console.log('updateJobs');
    this.jobUpdateLoading = true;
    this.jobUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/image-uploader/workspaces/${workspaceId}/jobs/${jobId}/updateProdImage`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateJobs|error=', error);
      this.jobUpdateError = true;
    } finally {
      this.jobUpdateLoading = false;
    }
  }

  @action
  async updateTask(workspaceId: string, id: string, status: string) {
    console.log('updateStatusTask');
    this.taskUpdateLoading = true;
    this.taskUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages-steps-tasks/${id}/status`,
        { id, status }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateStatusTask|error=', error);
      this.taskUpdateError = true;
    } finally {
      this.taskUpdateLoading = false;
    }
  }

  @action
  async updateTaskTargetDate(workspaceId: string, id: string, targetDate: string) {
    console.log('updateTaskTargetDate');
    this.taskUpdateLoading = true;
    this.taskUpdateError = false;
    try {
      const res = await basicRequestPatch(
        `/workspaces/${workspaceId}/stages-steps-tasks/${id}/targetDate`,
        { targetDate }
      );
      this.dataUpdateJob = res.data;
    } catch (error) {
      console.error('updateTaskTargetDate|error=', error);
      this.taskUpdateError = true;
    } finally {
      this.taskUpdateLoading = false;
    }
  }

  @action
  async getMyTasks(workspaceId: string) {
    console.log('getMyTasks');
    if (this.loadingMyTasks) {
      return;
    }
    this.loadingMyTasks = true;
    this.loadingErrorMyTasks = false;
    this.dataMyTasks = [];
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/stages-steps-tasks/my`);
      console.log('----getMyTasks|res.data=', res.data);
      runInAction(() => {
        this.dataMyTasks = res.data;
      });
    } catch (error) {
      console.error('getMyTasks|error=', error);
      runInAction(() => {
        this.loadingErrorMyTasks = true;
      });
    } finally {
      runInAction(() => {
        this.loadingMyTasks = false;
      });
    }
  }

  @action
  async getMyTaskOpen(workspaceId: string, id: string) {
    console.log('getMyTaskOpen');
    if (this.loadingMyTaskOpen) {
      return;
    }
    this.loadingMyTaskOpen = true;
    this.loadingErrorMyTaskOpen = false;
    this.dataMyTaskOpened = null;
    try {
      const res = await basicRequestGet(`/workspaces/${workspaceId}/stages-steps-tasks/${id}`);
      console.log('----getMyTaskOpen|res.data=', res.data);
      runInAction(() => {
        this.dataMyTaskOpened = res.data.task;
      });
    } catch (error) {
      console.error('getMyTasks|error=', error);
      runInAction(() => {
        this.loadingErrorMyTaskOpen = true;
      });
    } finally {
      runInAction(() => {
        this.loadingMyTaskOpen = false;
      });
    }
  }

  @action
  setLayoutPage(value: any) {
    this.layoutPage = value;
  }

  @action
  setLayoutPageJob(value: any) {
    this.layoutPageJob = value;
  }

  @action
  setTabsIndex(value: any) {
    this.tabsIndex = value;
  }

  //get jobs all list//
  @action
  async getListJobsQueue(workspaceId: string, stageId: string) {
    console.log('getList jobs queue');
    if (this.loadingQueue) {
      return;
    }
    this.loadingQueue = true;
    this.loadingQueueError = false;
    this.dataQueueList = [];
    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/jobs?stageId=' + stageId, {
        stageId,
      });
      console.log('getJobs queue|res.data=', res.data);
      runInAction(() => {
        this.dataQueueList = res.data.data as JobsDataType[];
      });
    } catch (error) {
      console.error('getJobs queue|error=', error);
      runInAction(() => {
        this.loadingQueueError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingQueue = false;
      });
    }
  }

  @action
  async getUserStatus(workspaceId: string) {
    console.log('get user status');
    if (this.loadingQueue) {
      return;
    }
    this.loadingQueue = true;
    this.loadingQueueError = false;
    this.dataStatus = {};
    try {
      const res = await basicRequestGet('/workspaces/' + workspaceId + '/usersOnlineStatus', {
        workspaceId,
      });
      console.log('get user status=', res.data);
      runInAction(() => {
        this.dataStatus = res.data.data;
      });
    } catch (error) {
      console.error('getJobs queue|error=', error);
      runInAction(() => {
        this.loadingQueueError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingQueue = false;
      });
    }
  }

  @action
  async changeUserStatus(workspaceId: string, onlineStatus: string) {
    console.log('change user status');
    if (this.loadingChangeStatus) {
      return;
    }
    this.loadingChangeStatus = true;
    this.loadingChangeStatusError = false;
    this.dataStatus = {};
    try {
      const res = await basicRequestPatch('/workspaces/' + workspaceId + '/usersOnlineStatus', {
        workspaceId,
        onlineStatus,
      });
      console.log('change user status=', res.data);
      runInAction(() => {
        this.dataStatus = res.data.data;
      });
    } catch (error) {
      console.error('getJobs queue|error=', error);
      runInAction(() => {
        this.loadingChangeStatusError = true;
      });
    } finally {
      runInAction(() => {
        this.loadingChangeStatus = false;
      });
    }
  }
}
